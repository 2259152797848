.Articlecontainer {
  padding-top: 0%;
  @media(min-width: 769px){
    padding-top: 4%;
  }
  @media (max-width: 576px) {
    padding-top: 30%;
  }
  @media (max-width: 485px) {
    padding-top: 40%;
  }
  @media (max-width: 425px) {
    padding-top: 50%;
  }
  @media (max-width: 375px) {
    padding-top: 60%;
  }
  @media (max-width: 375px) {
    padding-top: 70%;
  }
  @media (max-width: 300px) {
    padding-top: 90%;
  }
}

.happy-radio,
.lovers-radio {
  display: none;
}

/* the cards and toggle buttons are actually fat labels that can be clicked to set radio buttons */
label {
  display: block;
  cursor: pointer;
}
.selector {
  width: 100%;
  position: relative;
  min-height: 100%;
}

.baby-pick,
.mom-pick {
  grid-area: pick;
  height: 75%;
  margin: 50px auto 0;
  padding: 30px;
  border: 1px solid #ffffff;
  border-radius: 9999px;
  background-color: #b46d73;
  font-size: 1.25rem;
  -webkit-user-select: none;
  user-select: none;
}
.baby-pick::before,
.mom-pick::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
}

.baby-pick::before {
  margin-right: 10px;
  content: "\f0a8"; /* fa-arrow-circle-left */
}

.mom-pick::after {
  margin-left: 10px;
  content: "\f0a9"; /* fa-arrow-circle-right */
}

.app-header {
  display: flex;
  justify-content: center;
  height: 150px;
  align-items: center;
}
.app-header img {
  height: 50px;
}
.app-header > .app-name {
  font-size: 30px;
  margin: 0 20px;
}

.chat-system {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-box {
  width: 430px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.username-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
.username-label {
  padding: 0;
  margin: 10px 0;
}
.input {
  border-radius: 5px;
  border: 1px solid #eee;
  outline: none;
  width: 200px;
  height: 25px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  color: #777;
  background: #eee;
}

.message-area {
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: auto;
}

.message-control {
  display: flex;
  align-content: flex-end;
}

.message-control textarea {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-family: Poppins;
  box-sizing: border-box;
  padding: 5px;
  display: inline-block;
}

.message-control button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  outline: none;
  border: none;
  font-family: Poppins;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
}

.new-message-count {
  font-size: 10px;
  display: inline-block;
  background: #a54ea5;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.message-area ul {
  padding: 0;
  margin: 0;
  height: 570px;
}

.message-area ul li {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px;
}
.message-area ul .user-pic {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 40%;
  overflow: hidden;
}

.message-area ul .user-pic img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.message-area ul .message-text {
  background: white;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  margin: 3px 5px;
  max-width: 270px;
  font-family: Poppins;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 14px;
}

.message-area ul .sentMessage-text {
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border-radius: 5px;
  color: white;
  font-size: 15px;
  padding: 7px 7px;
  box-sizing: border-box;
  margin: 5px 5px;
  max-width: 270px;
  font-family: Poppins;
  border-radius: 14px;
}

.file-input-container {
  position: relative;
}
.file-input-container input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.file-input-container label {
  display: block;
  color: #a54ea5;
  padding: 0 10px;
  cursor: pointer;
  background: #eee;
  height: 100%;
  display: flex;
  align-items: center;
}

.image-container {
  background: #eee;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 0 5px;
}

.attachement-display {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 10px;
  background: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.attachment-name {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px;
}

.remove-attachment {
  font-size: 20px;
  font-weight: 500;
  margin: 0 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.ant-drawer-body {
  overflow: hidden;
}

.option {
  border-radius: 20px !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: none !important;
}

/* slider css */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  width: 100%;
  height: 550px;
}

.slider img {
  width: 20rem;
}

.slider {
  transform: scale(0.25);
  transition: transform 300ms;
  opacity: 0.4;
  margin: 0 auto;
}
.slider3{
  transform: scale(0.25);
  transition: transform 300ms;
  opacity: 0;
  margin: 0 auto;
}

.slider-2 {
  transform: scale(0.4);
  transition: transform 300ms;
  opacity: 0.4;
}

.activeSlide {
  transform: scale(0.6);
  opacity: 1;
}

.slider-page {
  transform: scale(0.6);
  opacity: 1;
}

.pageWeek img {
  border-radius: 50%;
  border: 8px solid #febcce;
}

.activeSlide img {
  border: 8px solid #fff;
  border-radius: 50%;
}

/* .arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  color: #fff;
}

.arrow svg {
  transition: color 300ms;
}

.next {
  right: 43%;
  top: -18%;
}

.prev {
  left: 43%;
  top: -18%;
} */

/* @media (max-width: 576px) {
}

@media(max-width: 576px){
  .next{
    right: 34%;
    top: -14%;
  }

  .prev{
    left: 33%;
    top: -14%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .next {
    right: 34%;
    top: -14%;
  }

  .prev {
    left: 33%;
    top: -14%;
  }
} */

@media (min-width: 576px) and (max-width: 768px) {
  /* .next {
    right: 28%;
    top: -20%;
  }

  .prev {
    left: 28%;
    top: -20%;
  } */
  .ftext {
    font-size: 24px !important;
  }
  #carouselWrap .carousel-item {
    margin-top: 0px;
  }
  #carouselWrap .carousel-control-next {
    position: absolute;
    right: 20% !important;
  }
}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .next {
    right: 42%;
    top: -14%;
  }
  .prev {
    left: 42%;
    top: -14%;
  }
} */

/* @media (max-width: 320px) {
  .prev {
    left: 30%;
    top: -14%;
  }
  .next {
    right: 30%;
    top: -14%;
  }
} */

/* when a radio button is set, swing the corresponding card fowards */
#baby-radio:checked ~ .selector .baby-card,
#mom-radio:checked ~ .selector .mom-card {
  animation-name: swing-forwards;
}

@keyframes swing-forwards {
  0% {
    z-index: 1; /* start behind other card */
    transform: scale(1);
  }
  50% {
    transform: translateX(var(--swing));
  }
  100% {
    z-index: 2; /* end in front of other card */
    transform: translateX(var(--overlap)) scale(1);
  }
}

/* when a radio button is set, swing the opposite card backwards */
#baby-radio:checked ~ .selector .mom-card,
#mom-radio:checked ~ .selector .baby-card {
  animation-name: swing-backwards;
}

@keyframes swing-backwards {
  0% {
    z-index: 2; /* start in front of other card */
    transform: translateX(var(--overlap));
  }
  50% {
    transform: translateX(var(--swing));
  }
  100% {
    z-index: 1; /* end behind other card */
  }
}
.homeBabyIcon img {
  width: 97px;
}
.weekday {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  /* identical to box height */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* patient_web */

  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border-radius: 10px 0px 0px 10px;
}
.weekinfo {
  width: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  padding: 30px;
}

/****************** Home changes 20.10.22 *********/
.babyAnimWrap {
  position: relative;
  width: 100%;
  width: 100%;
  padding: 3% 0%;
  height: fit-content;
  margin-top: -20px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}
h3.headingAni {
  color: #ffffff;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  margin-top: 5px;
}
span.headingSpan1 {
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}
span.headingSpan2 {
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
/************************************/
.mobileHomeWrap {
  width: 100%;
  position: relative;
  display: none;
  margin-top: 35%;
}
.showMobile {
  display: none;
}
h1.curatedforyou {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  color: rgb(31, 39, 74);
  @media (max-width: 768px) {
    font-size: 24px;
  }
}
.homecard {
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 15px;
  padding: 15px;
}
h5.homeCardHeading {
  font-size: 16px;
  line-height: 22px;
  color: rgb(31, 39, 74);
}
.homeCardReadMore {
  position: absolute;
  right: 15px;
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
.ReadMore{
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
.ReadMore1{
  position: absolute;
  left: 15px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
p.homePara2 {
  font-size: 16px;
  color: rgb(31, 39, 74);
}
@media (max-width: 768px) {
  .hideMobile {
    display: none;
  }
  .mobileHomeWrap {
    display: block;
    margin-top: 5%;
  }
}
.redm {
  color: #a54ea5;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}
.ftext {
  font-size: 32px;
  font-weight: 600;
  padding-right: 5px;
  padding-left: 5px;
  color: white;
}

@media only screen and (orientation: portrait) {
  .ftext {
    font-size: 20px;
    font-weight: 600;
    padding-right: 5px;
    padding-left: 5px;
    color: white;
  }
}
@media only screen and (orientation: portrait) {
  .texmore {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mobileHomeWrap {
    margin-top: 0%;
  }
}
.ims {
  border-radius: 15px;
  width: 100%;
  height: 100px;
}
.svapp {
  width: 55%;
}
.svapp:hover{
  transform: scale(1.1);
  transition: 0.3s all;
}
.resourceImg {
  width: 40%;
}
@media (max-width: 576px) {
  .resourceImg {
    width: 50% !important;
  }
  #carouselWrap .carousel-control-next {
    right: 0% !important;
  }
}
/***** carousel CSS 22.11.2022 ********/
/*
#carouselWrap .carousel{
  background-color: none;
  box-shadow:none;
}
#carouselWrap .carousel-item{
  padding: 30px;
  z-index: 1;
}
.carouselDiv{
  width:100%;
  position: relative;
  border: 1px solid #A54EA5 !important;
  box-shadow: 1px 1px 12px #efe6ef;
  background-color:none !important;
  border-radius:30px;
  z-index: -1;
}
#carouselWrap h1{
  font-size: 36px;
}
#carouselWrap p{
  font-size: 22px;
}
.carousel-control-prev, .carousel-control-next{
  width: 50px;
  height:50px;
  position: absolute;
  left:-25%;
  padding: 3px;
  z-index: 999999;
  border: 5px solid #A54EA5;
}
*/
/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .mobileHomeWrap {
    margin-top: 10%;
  }
  .ftext {
    font-size: 22px !important;
  }
  .galaxyFoldFont span {
    font-size: 16px !important;
  }
  .next {
    right: 15%;
    top: -18%;
  }
  .prev {
    left: 15%;
    top: -18%;
  }
  .resourceImg {
    width: 70% !important;
  }
  .tribeModalIcon {
    width: 35px;
    margin-top: 8px;
  }
}
@media (max-width: 320px) {
  #galaxyFoldSmily div {
    width: 50%;
    margin-bottom: 30px;
  }
  #galaxyFoldSmily p {
    text-align: center;
  }
}

.headingH2 {
  /* text-align: center; */
  margin-bottom: 2rem;
  margin-top: 3rem;
  /* font-weight: 600; */
  /* font-size: 48px; */
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
}

.no-appointment {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  width: 60%;
  margin: 0rem auto;
  padding: 3rem;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  @media(max-width: 576px) {
    flex-direction: column;
    padding: 1.5rem;
  }
  @media(max-width: 768px){
    width: 100%
  }
  @media (min-width: 1800px) {
    width: 55%;
  }
}

.no-appointment-img {
  height: 10rem;
  width: 20rem;
}

.no-appointment-heading {
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    margin-top: 16px;
  }
}

a.appointmentsBtn-carousel {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 32px 10px 32px;
  text-decoration: none;
  background: linear-gradient(270deg, #ff7cc9 0%, #cf509b 100%) !important;
  border-radius: 30px;
  color: #ffffff;
  border: none;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.submitRspn{
  text-align: left;
  @media(max-width: 576px){
    text-align: center;
  }
}
.mom-header {
  display: flex;
  justify-content: space-between;
}

.baby-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  color: white;
}

.baby-left-arrow {
  font-size: 34px;
  /* margin-top: 20px; */
  margin-left: 32px;
  color: white;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 0px;
  }
}

.baby-right-arrow {
  font-size: 34px;
  /* margin-top: 20px; */
  margin-right: 32px;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 0px;
  }
}

/* custom-loader.css */
.custom-loader {
  display: flex;
  align-items: end;
  justify-content: start;
}

.dot-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #000; /* Change this color as needed */
  border-radius: 50%;
  margin: 0 4px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.week-font {
  font-size: 30px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.woman-animation, .man-animation, .mom-animation {
  animation: bounce 2s ease infinite;
}

.baby-size{
  display: inline-block;
  height: 48px;
  @media(max-width: 768px){
    height: 90px;
  }
}