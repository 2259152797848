h1.moodometerModalHeading{
    margin: 15px 15px 0px 15px;
    padding: 0px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: #A54EA5;
}
ul.fellingList{
    margin: 0px 15px 0px 30px;
    padding: 0px;
}

ul.fellingList li{
    display: list-item;
    font-size: 20px;
    line-height: 20px;
    color: #a64fa6;
}
ul.fellingList li span{
    font-size: 16px;
    line-height: 20px;
    color: #AE7FB7;
    font-weight: normal;
}
.fellingSpeek{
    font-size: 0.98rem;
    line-height: 0.98rem;
    font-family: 'Poppins';
    color: #A64FA6;
    font-weight:500;
    text-decoration: underline !important;
}