.gifttext {
    padding: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1F274A;
}
.logogift {


    top: 250px;
}
.subgift {

    width: 598px;
    height: 60px;
    padding: 30px;
    top: 500px;
}
.subgiftright {
    left: 133px;
    top: 21px;
}
.amount {
    
    width: 80%;
    border-radius:10px;
}
.btngift {
    width: 80%;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    border: solid 1px #a54ea5;
    border-radius: 42px;
    padding:8px;
    color:#fff;
    margin-top:100px;
    margin-bottom:35px;
}
.headvalue {
   
    padding-bottom:10px;
}
.cont{
    text-align:center;
}
.copn{
    margin:20px;
}

@media (max-width: 768px) {
    .headvalue {
        padding-bottom: 1px;
    }
    .gifttext {
        padding: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #1F274A;
    }
}