body {
  font-family: Poppins !important;
  color: #1f274a;
}
.regitLogo {
  margin-top: 15px;
}
h1.regitWelcome {
  font-size: 34px;
  font-weight: 600;
}
h6.genderLabH6 {
  font-size: 16px;
  font-weight: 400;
  color: #1f274a;
}
.Regitlabel {
  font-size: 15px;
  font-weight: 400;
}
.regitForgotLbl {
  font-size: 16px;
  font-weight: 600;
  color: #a54ea5;
}
.regitBtn {
  width: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
  background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
ul.regitButtonList {
  margin: 0px;
  padding: 0;
}
ul.regitButtonList li {
  display: block;
  list-style-type: none;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid rgba(31, 39, 74, 0.2);
  border-radius: 10px;
}
ul.regitButtonList li:last-child {
  margin-bottom: 0px;
}
ul.regitButtonList li a {
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #1f274a;
}
ul.regitButtonList li a svg {
  font-size: 30px;
  line-height: 16px;
  height: 40px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: -5px;
}
.regitFacebook {
  border-radius: 15px;
  color: #4267b2;
}
.regitGoogle {
  color: rgb(219, 50, 54);
}
.regitFacebook svg {
  border-radius: 15px;
}
.regitLableBottom {
  font-size: 16px;
  font-weight: 600;
  color: #1f274a !important;
}
.regitLableBottom a {
  color: #a54ea5 !important;
  text-decoration: none;
}
.logos-dob{
  margin-top: 16px;
}
.img-dob-fix{
  width: 100%;
  height: 100vh;
}
/* .ant-radio-button-checked{
  background: #a54ea5;
} */
@media (max-width: 768px) {
  .regitLogo {
    display: none;
  }
  .regitMobileImg {
    background: #f8f8f8;
    border-radius: 0px 0px 30px 30px;
  }
  h1.regitWelcome {
    font-size: 24px;
    line-height: 32px;
    text-align: left !important;
  }
  .regitPara1 {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .Regitlabel {
    display: none;
  }
  .regitForgotLbl {
    font-size: 14px;
    line-height: 14px;
  }
  .regitBtn {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0px;
  }
  ul.regitButtonList li {
    box-shadow: 1px 6px 22px #ede8ed;
  }
  ul.regitButtonList li a {
    font-size: 16px;
  }
  .registerImg img {
    margin: 0px;
  }
  .registerImg {
    background-color: #f8fcff;
    height: 100%;
  }
  .img-dob-fix{
    width: 100%;
    display: none;
  }
}

.next-btn {
  @media (min-width: 577px) and (max-width: 1000px) {
    margin: 0px auto;
    width: 40%;
  }
  @media (min-width: 1001px) and (max-width: 1440px){
    margin: 0px auto;
    width: 50%;
  }
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .genderInliine label {
    width: 100%;
    height: 80px !important;
  }
  .radioLb {
    font-size: 12px;
  }
}
