.empmsg {
  border-radius: 15px;
  color: #afafaf;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.appointmentsHeading{
  font-size: 24px;
  line-height: 32px;
  /*height: 65px;*/
  font-weight: 600;
  text-align: left;
  /* margin-left: 8px; */
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appointmentsHeadingH1 {
  font-size: 28px;
  line-height: 32px;
  /*height: 65px;*/
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appointmentsPara{
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 16px;
  margin-bottom: 8px;
}
.appointmentsPara1 {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 32px;
  margin-bottom: 12px;
}
ul.appointmentsList1{
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  text-align: left;
  margin-left: -12px;
}
ul.appointmentsList {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
}
ul.appointmentsList1 li{
  list-style-type: none !important;
  display: inline-block;
  padding: 0px 15px;
  margin: 0px 0px 8px 0px;
}
ul.appointmentsList li {
  list-style-type: none !important;
  display: inline-block;
  padding: 0px 15px;
  font-size: 14px;
}
a.appointmentsBtn {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  /* padding: 6px 10px; */
  text-decoration: none;
  /* background: linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important; */
  color: #a54ea5;
  /* border-radius: 30px; */
  /* color: #FFFFFF; */
  border: none;
  text-align: center;
}
.appmntImg{
  background: lightgray;
  border-radius: 20px;
}
.appmntImg2{
  background: lightgray;
  border-radius: 20px;
  min-height: 170px !important;
  max-height: 170px !important;
  overflow: hidden;
}
.appmntImg img {
  border-radius: 15px;
  width: 100%;
}
.appmntImg2 img {
  border-radius: 15px;
  width: 100%;
  height: 100% !important;
}
@media(min-width: 577px) and (max-width: 768px){
  .appointmentsHeadingH1{
    margin-top: 16px;
  }
  .appointmentsPara1{
    font-size: 12px;
    margin-bottom: 8px;
  }
  ul.appointmentsList {
    margin-bottom: 8px;
  }
  ul.appointmentsList li{
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .appointmentsHeadingH1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .appointmentsHeading{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  a.appointmentsBtn {
    font-size: 14px;
  }
  ul.appointmentsList {
    margin-bottom: 10px;
  }
  ul.appointmentsList1{
    margin-bottom: 8px;
  }
  ul.appointmentsList1 li{
    margin: 3px 0px; 
  }
  ul.appointmentsList li {
    margin: 3px 0px;
  }
  .appointmentBox {
    padding: 5px 5px 60px 5px !important;
  }
  /*
    .appointmentsHeadingH1{
        font-size: 24px;
        line-height: 26px;
    }
    ul.appointmentsList li{
        margin:3px 0px
    }
    */
}
.svimg {
  height: 180px;
  width: 100%;
  @media(max-width: 576px){
    height: 135px;
  }
}
.conts {
  position: absolute;
  bottom: 0;
  line-height: 5;
}

@media only screen and (orientation: portrait) {
  .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    font-size: 12px;
  }
  .reportTabWrap .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
    padding: 9px 13px;
  }
}

.itemscard {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 8px;
  height: 100%;
}

.cardSection3{
  position: absolute;
  z-index: 99;
  left: 0;
  bottom: 15px;
}