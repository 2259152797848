#title-bar{ 
    margin-top: 10%;  
}

.consult-search{
    width: 40%;
    border-radius: 27px; 
    margin-top: 2%;
}

.filter{
    margin-left: 1%; 
}

.card-img{
    margin-top: 5%;
    border-radius: 10px !important;
}

.today-img{ 
    width: 100%;
    padding: 5%;
    margin-right: 10%;
    border-radius: 30px !important;
     
}

.ant-card-body {
    padding: 8%;
    padding-left: 1%;
}

.consult-info{
    padding-left: 5%;
}

.doc-card{
    box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
    border-radius: 8px;
}

#next-row{
    margin-top: 5%; 
}