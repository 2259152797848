/* .ant-checkbox-wrapper {
    margin-left: 8px;
} */
.language-checkbox.ant-checkbox-wrapper{
width: 150px;
margin: 0px 15px 0px -20px;
}
.concern-checkbox.ant-checkbox-wrapper{
width: 200px;
margin: 5px;
}
.expertFilter{
    margin-left: 15px;
    cursor: pointer;
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:768px) {        
    .expertFilter{
        margin-left: 5px;
        margin-top: 10px;
    }
    .expertHeading{
        font-size: 24px;
        text-align: left;
    }
}
@media (max-width:281px) {        
    .expertFilter{
        margin-left: 0px;
        margin-top: 10px;
    }
}