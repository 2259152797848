.SuccessModal{
    
}
.ant-form-item-explain-error {
    padding-left: 0px;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {

margin-left:49%
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-conten {
    margin-right: 0px
}

fils {
    width: auto;
    height: max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #EDE8ED;
    border: none;
}
    fils:last-child

{
    width: auto;
    height:max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #EDE8ED;
    border: none;
}

fils:first-child {
        width: auto;
        height: max-content;
        border-radius: 40px;
        box-shadow: 1px 6px 22px #EDE8ED;
        border: none;
    }
.ant-checkbox-wrapper-checked {
    border: 1px solid blue;
}
.linkstag {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    /* identical to box height */
    text-decoration:none;
    padding:0px 15px;
    color: #a54ea5;
    text-align:right;
    @media (min-width: 769px) and (max-width: 1024px){
        font-size: 14px;
    }
}

.linkstag:hover {
    color: black
}
.acts {
        color: black
    }

.attach-report-btn{
    margin-top: 24px;
    @media(max-width:576px){
        margin-top: 12px;
    }
}

.appointment-id{
    @media (min-width: 321px) and (max-width: 375px){
        font-size: 13px !important;
        margin-left: 8px !important ;
    } 
    @media (min-width: 280px) and (max-width: 320px){
        font-size: 12px !important;
        margin-left: 8px !important;
    }
}
@media (max-width: 768px) {
    .linkstag {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size:13px;
        /* line-height: 8px; */
        text-decoration: none;
        color: #a54ea5;
    }
    .linkstag:first-child{
        padding-left: 0px !important;
    }
    h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 10px;
        line-height: 1.5;
    }
    .rinfo {
        font-size: 16px;
        font-weight: 500;
    }
    .helthboxHeading{
        font-size: 1rem;
        margin: 15px 0px;
    }   
}
.rinfo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    /* identical to box height */
    color: #1F274A;
    @media(max-width: 332px){
        font-size: 19px;
    }
}
h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
}
.uploadModalHeader .ant-modal-header{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    margin-left: 0px;
}
.ant-modal-confirm-btns button{
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
}
/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    span.hideGalaxyFold{
        display: none;
    }
    .ant-modal-confirm .ant-modal-body{
        padding: 15px !important;
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
        margin-left:0px;
    }
    .ant-modal-confirm-content p span{
        width: 100% !important;
        position: relative;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding: 0px 5px;
    }
    .galaxyFoldUpload {
        width: 100%;
        position: relative;
    }
    .helthboxHeading{
        font-size: 1rem;
        margin: 15px 0px;
    }
  }

#paymentForm
{
    width: 100%;
    height: auto;
}

@media screen and (min-width: 576px)
{

}