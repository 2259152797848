
.tabbable-panel {
    width: 100%;
    position: relative;
}  
.tabbable-line > .nav-tabs {
border: none;
background-color: #fff;
border-radius: 10px 10px 0px 0px;
margin-bottom: 30px;
border-bottom:1px solid rgba(31, 39, 74, 0.2);;
}
.tabbable-line > .nav-tabs > li > a {
border: 0;
color: #1F274AB2;
display: block;
font-size: 15px;
font-weight: 500;
padding: 15px 30px;
text-decoration: none;
text-wrap: nowrap;
}
.tabbable-line > .nav-tabs > li > a > i {
color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li a:hover {
border-bottom: 4px solid #a54ea5;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
border: 0;
background: none !important;
color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
margin-top: 0px;
}
.tabbable-line > .nav-tabs > li a.active {
border-bottom: 4px solid #a54ea5;
position: relative;
color:#a54ea5;
}
.tabbable-line > .nav-tabs > li a.active > a {
border: 0;
color: #a54ea5;
}
.tabbable-line > .nav-tabs > li a.active > a > i {
color:#a54ea5;
}
.tabbable-line > .tab-content {
    border-radius: 0px 0px 10px 10px;
}
.portlet .tabbable-line > .tab-content {
padding-bottom: 0;
}
.addTabBg1{
    background-color: #FFFFFF;
    padding: 15px;
}
.addReportRightWrap {
    position: relative;
    right: 0;
    top: -10px;
}
.addReportBtn{
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    margin-right: 15px;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 42px;
    color: #fff;
}
.addReportBtn:hover{
    color: #FFF;
}
hr.borderDivider{
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin: 15px;
}
span.fileNameHeader{
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
}
a.fileNamedownload{
    text-decoration: none;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    margin-left:15px;
    font-style: normal;
    color: #a54ea5;
}

a.fileNamedownload svg{
    margin-left:10px;
}


@media (max-width: 768px) {
	span.fileNameHeader{
        line-height:16px;
        word-wrap: break-word;
    }
    .addReportRightWrap {
     right:0;
    }
}
/******* Test Report *******/
.testReportCard{
    width: 100%;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
}
.testReportCardInner{
    width: 100%;
    position: relative;
    overflow: auto;
    margin-bottom: 15px;
}
.testReportLeft{
    width:15%;
    float: left;
    clear:both;
    padding-top:5px;
}
.testReportRight{
    width: 85%;
    float: left;
}
.testReportCardHead1{
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color:rgba(31, 39, 74, 0.7);
}
.testReportCardHead2{
    font-size: 15px;
    line-height:22px;
    font-weight: 500;
    color:#1F274A;
}
.testReportNormal{
    font-size: 14px;
    line-height: 36px;
    color: #22A63F;
    font-weight: 600;
}
.reportSearch {

}
.reportSearchInput{
    background: #F8F9FA;
    border: 1px solid #EFEFEF;
    border-radius: 30px !important;
    width:200px;
}
.reportSearchInput input{
    background: none;
}
h3.noReportHeading{
    font-size: 33px;
    font-weight: 500;
    margin-top: 3%;
}
.tas {
    width: 100%;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px;
    margin: 2px;
    background: white;
    box-shadow: 1px 6px 22px #EDE8ED;
}
.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}
.selectedFilter{
    border: 1px solid transparent;
    transition: border-color 0.3s, color 0.3s;
    border-color: #a54ea5;
    color: #a54ea5;
}
.resetButton{
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    color: #fff;
}
.resetButton:hover ,.resetButton:active, .resetButton:focus{
    color: #FFF;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}