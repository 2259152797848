body {
  font-family: Poppins !important;
  color: #1f274a;
}
.regitLogo {
  margin-top: 15px;
}
h1.regitWelcome {
  font-size: 34px;
  font-weight: 600;
}
p.regitPara1 {
  font-size: 20px;
  color: rgba(31, 39, 74, 0.7);
}
.Regitlabel {
  font-size: 15px;
  font-weight: 400;
}
.regitForgotLbl {
  font-size: 16px;
  font-weight: 600;
  color: #a54ea5;
}
.regitBtn {
  width: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
  background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
ul.regitButtonList {
  margin: 0px;
  padding: 0;
}
ul.regitButtonList li {
  display: block;
  list-style-type: none;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid rgba(31, 39, 74, 0.2);
  border-radius: 10px;
}
ul.regitButtonList li:last-child {
  margin-bottom: 0px;
}
ul.regitButtonList li a {
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #1f274a;
}
ul.regitButtonList li a svg {
  font-size: 30px;
  line-height: 16px;
  height: 40px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: -5px;
}

.socialIconMargin {
  margin-right: 15px;
}

.regitLableBottom {
  font-size: 16px;
  font-weight: 600;
  color: #1f274a !important;
}
.regitLableBottom a {
  color: #a54ea5 !important;
  text-decoration: none;
}
.regitRightImage2 {
  position: fixed;
  right: 0;
  margin-top: 10%;
  bottom: -90px;
}

@media (max-width: 576px) {
  .img-fix-login {
    width: 100%;
    display: none;
  }
  .contents-login {
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .regitRightImage2 {
    position: relative;
    right: auto;
    margin-top: auto;
    bottom: auto;
  }
  .regitLogo {
    display: none;
  }
  .regitMobileImg {
    background: #f8f8f8;
    border-radius: 0px 0px 30px 30px;
  }
  h1.regitWelcome {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .regitPara1 {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .Regitlabel {
    display: none;
  }
  .regitForgotLbl {
    font-size: 14px;
    line-height: 14px;
  }
  .regitBtn {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0px;
  }
  ul.regitButtonList li {
    box-shadow: 1px 6px 22px #ede8ed;
  }
  ul.regitButtonList li a {
    font-size: 16px;
  }
  .img-fix-login {
    width: 100%;
    display: none;
  }
}

@media (max-width: 992px) {
  .img-fix-login {
    width: 100%;
    display: none;
  }
}
.img-fix-login {
  width: 100%;
  height: 100%;
}

.ModalHeader .ant-modal-header {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.logpg-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: rgba(31, 39, 74, 0.7);
}

.lbsc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #1f274a;
}

.title-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #1f274a;
  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.logos-login {
  margin-top: 16px;
  margin-bottom: 20px;
}

.container-fluid {
  max-height: 100vh;
}
