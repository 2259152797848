@media (max-width: 1200px) {
  .signupHead {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .signupImg {
    width: 100%;
  }
  .submitRspn2 ul li a {
    padding: 10px 15px;
  }
  .formData {
    padding-right: 0px;
  }
  .carouselWWrap {
    width: 70% !important;
  }
  .carouselWWrapDr {
    width: 70% !important;
  }
  .nav-link {
    margin: 0px 15px !important;
  }
  .homeTopBanner {
    margin-top: 50px;
  }
  .iosPlayStore {
    margin-bottom: 60px;
  }
  .homeCurveBg {
    top: -35%;
    height: 210%;
  }
  .homeCurveBg {
    width: 100%;
    height: 204%;
  }
  .navRight {
    position: relative;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .message-area {
    height: 500px;
  }
  .signupHead {
    text-align: center;
  }
  .signupImg {
    width: 100%;
  }
  .signupHead {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .submitRspn2 {
    margin-bottom: 120px;
  }
  .submitRspn2 ul li {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .submitRspn2 ul li:last-child {
    margin-left: 0px;
  }
  .datePickerlbl {
    text-align: center;
  }
  .datePicker {
    width: 100%;
  }
  .radioInterest {
    width: 100%;
  }
  .doc-info {
    padding: 0px 0px 30px 0px;
  }
  .doctorHeadPrent {
    padding-top: 10%;
  }
  .doctorHeadSlide {
    margin-top: 6%;
  }
  .carouselWWrap {
    width: 87% !important;
  }
  .carouselWWrapDr {
    width: 87% !important;
  }
  .doc-img {
    width: 100%;
  }
  .doctorParaInline ul {
    padding-left: 2rem;
  }
  .doctorParaInline ul li {
    font-size: 18px;
  }
  .doctorParaInlinemobile ul {
    padding: 0;
  }
  .carousel-control-next {
    right: -15px !important;
  }
  .carousel-control-prev {
    left: -15px !important;
  }
  #drTestimonials {
    width: 100%;
  }
  .doctorHeadPrent {
    font-size: 24px;
  }
  .doctorHeadChild {
    font-size: 34px;
  }
  .webWeader {
    top: 0% !important;
  }
  .drHeader {
    background: none !important;
    box-shadow: none;
    padding-left: 0px !important;
  }
  #navbarToggler {
    background: #ffffff;
    padding: 15px 30px;
    border-top-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  /*********** Mobile Menu Tahsil ******************/
  #overlayCloseRight {
    position: fixed;
    width: 20%;
    height: 100%;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    font-size: 2rem;
    color: #ffffff;
    text-align: right;
    padding-right: 5%;
  }
  #navbarToggler {
    position: fixed;
    overflow-y: scroll;
    width: 80%;
    min-width: 80%;
    max-width: 80%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    border-radius: 0px !important;
    transition: all 0.01s ease !important;
    transition: none;
  }

  .show > #overlayCloseRight {
    display: block !important;
  }
  .collapsing {
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    visibility: hidden;
  }
  .collapse.show {
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    visibility: visible;
  }

  /*********** End Mobile Menu ****************/
  .drHeader {
    padding-right: 0px !important;
  }
  .nav-link {
    margin-left: 0px !important;
  }
  .homeCureHead {
    padding-top: 60px;
    text-align: center;
  }
  .homeCureHead2 {
    text-align: center;
  }
  .homeCuratedPara {
    text-align: center;
  }
  .homeTopBanner {
    display: none !important;
  }
  .homeTopBannerMobile {
    display: block;
    position: absolute;
    right: 0;
    top: -1%;
  }
  .homeheading {
    margin-top: 30px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-family: Poppins;
    font-size: 20px;
  }
  .homePlayStore {
    margin-top: 20px;
  }
  .iosPlayStore {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .homePlayStore img,
  .iosPlayStore img {
    width: 80%;
  }
  .iosPlayStore img {
    width: 70%;
  }
  .homeCureHead2 {
    margin-top: 15px;
  }
  .homeCurveBg {
    display: none !important;
  }
  .homeCurveBgWrap {
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    padding: 30px 0px;
  }
  .homeCardPrgaImg,
  .homeCardPrgaPara {
    text-align: center;
  }
  .homeCardPrgaPara {
    margin-bottom: 60px;
  }
  .homeSmilyWrap {
    padding-top: 60px;
  }
  .smilyHeading {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0px;
  }
  .smilyPara {
    font-size: 14px;
  }
  .smilyCont {
    width: 100%;
    padding: 30px 0px;
  }
  .weeklyHeWrap {
    margin-top: 100px;
  }
  .pregaHeading {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .baby-card {
    width: 80% !important;
    min-height: 100% !important;
    right: 20px !important;
    margin-top: 0px !important;
  }
  .mom-card {
    width: 80% !important;
    min-height: 100% !important;
    left: 20px !important;
    margin-top: -20px !important;
  }
  .curatedWrap {
    padding-top: 30px;
  }
  .containerLeftPara {
    font-size: 12px;
    line-height: 20px;
    top: -10px;
  }
  .containerRightPara {
    font-size: 12px;
    line-height: 20px;
    bottom: -10px;
  }
  .homeVides {
    margin-top: 80px;
  }
  button.consultBtnResp {
    padding: 7px 10px;
  }
  #homeTestimonials {
    width: 100%;
  }
  .hideMobile {
    display: none;
  }
  .homeSmilyWrap {
    background: #ffffff;
  }
  .atEasySpace {
    padding: 10px 0px 0px 0px;
  }
  .atEasyCustomTile {
    margin-top: 0px;
  }
  .pregaHeading {
    font-size: 20px;
  }
  .homeBabyHeading1,
  .homeBabyHeading2,
  .curatedTitle,
  .homeHelthHead {
    font-size: 30px;
    text-align: center;
  }
  .expertWrap {
    /* margin-top: 8px; */
    margin-top: 0px;
  }
  .themeBtnLink {
    margin-left: 65px;
  }
  .expertHeading {
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 0px;
  }
  .expertAlign {
    text-align: left;
  }
  .drListingfilter {
    float: right;
    margin-top: 15px;
  }
  .consultSearch {
    width: 80% !important;
    margin-left: auto;
    margin-bottom: 30px;
  }
  .listImgWrap {
    height: auto !important;
    margin-bottom: 30px;
  }
  .nonPregHomeBanner {
    top: 4%;
  }
  .nonPregHomeSpace {
    padding-bottom: 0px;
  }
  .nonHomePlayStore img {
    width: 45%;
    margin: 10px 0px;
  }
  .nonIosPlayStore img {
    width: 40%;
    margin: 10px 0px;
  }
  .pageWrap {
    padding: 70px 0px;
  }
  .pageWrap3 {
    padding: 60px 0px;
  }
  .pageWrap4{
    padding: 75px 0px;
  }
  .dtDetailsHead,
  .drDetailsHeading2 {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
  .drDetailsPara1 {
    text-align: center;
    margin-top: 15px;
  }
  .drDetailsHeading3 {
    text-align: center;
  }
  .dateSlotBorder {
    width: 100% !important;
    margin: 0;
  }
  .drReadBox {
    background: #fff;
    padding: 15px 15px;
    border-radius: 10px;
  }
  .drReadImgRound {
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    border-radius: 10px;
  }
  .drReadHeading {
    text-align: left;
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .drReadComment {
    margin-top: 3px;
    text-align: left;
    font-size: 14px;
    line-height: 14px;
  }
  .profileRadioLbleLarge {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-family: Poppins;
    font-weight: 600;
    margin-top: 1%;
  }
  .supportTribeSelect {
    float: none;
    text-align: center;
    margin-top: 50px;
  }
  .supportTribeTitle {
    text-align: center;
  }
  .cardSection1, .cardSection2, .cardSection3{
    height: auto !important;
  }
  
  .appmntImg img{
    min-height: 250px !important;
    max-height: 250px !important;
  } 
  .mobile-block{
    display: block !important;
  }
  .drFees{
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .listSideWrap {
    padding: 0;
    width: 80%;
  }
  .message-area {
    height: 480px !important;
  }
  .signupHead {
    text-align: center;
  }
  .signupImg {
    width: 100%;
  }
  .formData {
    padding: 0px 15px;
  }
  .submitRspn {
    text-align: center;
  }
  .submitRspn2 button {
    width: 100%;
  }
  .submitRspn2 button:last-child {
    margin-left: 0px;
  }
  .loginImg {
    width: 100%;
  }
  .mobileLoginBg {
    background-color: #f8fcff;
  }
  .submitRspn2 ul li {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .submitRspn2 ul li:last-child {
    margin-left: 0px;
  }
  .carouselWWrap {
    margin-top: 10%;
    width: 100% !important;
  }
  .carouselWWrapDr {
    margin-top: 10%;
    width: 100% !important;
  }
  .weeklyHeWrap2 {
    padding-top: 150%;
  }
  .reportTabWrap {
    padding: 15px 0px 0px 0px !important;
  }
  .loginMobileOverlap {
    margin-top: -80px !important;
    background-color: #ffffff;
    z-index: 1;
  }
  .giftValueHead {
    margin-top: 30px;
  }
}

@media (max-width: 281px) {
  #navbarToggler {
    padding-left: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .mt-tab-blog {
    margin-top: 80px !important;
  }
  /* .mt-tab-video {
    margin-top: 48px !important;
  } */
}

@media (min-width: 1024px) {
    .upcommingDiv{
        width: 60% !important;
    }
}

@media(max-width: 1200px){
  .drHeader {
    /* background: #fff !important; */
    box-shadow: none;
    padding-left: 0px !important;
  }
}

@media (max-width: 768px) {
    .containerWidth{
        max-width: 540px !important;
        width: 100% !important;
    }
}

@media(max-width: 576px){
  .mobile-height{
      height: 48px;
  } 
}

@media(min-width: 279px) and (max-width: 374px){
  .atEasySpace{
    margin-top: 85%;
  }
}

@media(min-width: 375px) and (max-width:424px){
  .atEasySpace{
    margin-top: 65%;
  }
}

@media(min-width: 425px) and (max-width:767px){
  .atEasySpace{
    margin-top: 70%;
  }
}
@media(min-width: 768px) and (max-width: 992px){
  .atEasySpace{
    margin-top: 10%;
  }
}
@media(min-width: 993px){
  .atEasySpace{
    margin-top: 5%;
  }
}

@media(min-width: 576px) and (max-width: 769px){
  .tab-margin{
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
}