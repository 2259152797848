.row-Doc {
  /* margin-top: 10%;  */
  text-align: center;
}

.carousel {
  box-shadow: 1px 1px 12px #efe6ef;
  background-color: #fff;
  border-radius: 28px;
}

.doc-text {
  font-size: 15px;
}

/* .Spec-card{
    margin-top:126px;
} */

.cards {
  width: 100% !important;
  margin: 0px !important;
  height: auto !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.empmsg {
  border-radius: 15px;
  color: #afafaf;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.tabsbg .ant-tabs-tab-active {
  background: #c473c4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.sc {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.svf {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 21px;
  background: lightgray;
}

@media (max-width: 768px) {
  .cards {
    width: 100% !important;
    margin: 0px auto !important;
    margin-bottom: 30px !important;
  }
  .cards img {
    margin-bottom: 30px;
  }
  .cards h5 {
    font-size: 1rem;
  }
  .carcons {
    display: inline-flex;
  }
  .cdzbPz {
    font-family: Poppins;
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    color: white;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }
  .iasVts .customslides {
    position: relative;
    background: #b35fb3;
    box-shadow: 1px 1px 12px #efe6ef;
    border-radius: 28px;
    border: 1px solid #a64fa6;
    padding: 5%;
  }
  .iasVts p {
    font-size: 18px;
    color: #fefefe;
  }
}
@media (max-width: 281px) {
  .cards img {
    margin-bottom: 10px;
  }
  .cards h5 {
    font-size: 0.7rem;
  }
}
/******** Upcoming appointment Heading *******/
h1.headingH1 {
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 3%;
  margin-top: 5%;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  text-align: center;
  @media (max-width: 550px) {
    margin-top: 10%;
    font-size: 38px;
    line-height: 40px;
  }
}
h1.headingH1 span {
  font-size: 48px;
  @media (max-width: 550px) {
    font-size: 20px;
  }
}
.customCarouselArrow a.carousel-control-next {
  visibility: visible;
  right: -15% !important;
  @media (max-width: 550px) {
    right: -5% !important;
  }
}

.customCarouselArrow a.carousel-control-prev {
  visibility: visible;
  left: -15% !important;
  transform: scaleX(-1);
  @media (max-width: 550px) {
    left: -5% !important;
  }
}

a.appointmentsBtn-carousel {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 16px;
  text-decoration: none;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 30px;
  color: #ffffff;
  border: none;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.submitRspn {
  text-align: left;
  @media (max-width: 576px) {
    text-align: center;
  }
}

.mt-container {
  /* margin-top: 9%; */
  margin-top: 8%;
  @media screen and (max-width: 768px) {
    margin-top: 4%;
  }
}

.upcommingDiv {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  /* width: "75%"; */
  margin: 0rem auto 2rem auto;
  padding: 3rem;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.no-upcoming-text {
  display: "flex";
  flex-direction: column;
  @media (max-width: 576px) {
    margin-top: 24px;
  }
}

.upcomingIcon {
  height: 10rem;
  width: 20rem;
}
