.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
@media only screen and (orientation: portrait) {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
    width: 100%;
  }
}

.logpic {
  margin-bottom: 30px;
  border-radius: 50%;
}
.cliimg {
  cursor: pointer;
  margin-top: 100px;
  margin-left: -48px;
}

#interest label,
#tribe label,
#menstrualDetail label {
  margin-right: 15px;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  #interest label,
  #tribe label,
  #menstrualDetail label {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.profile-pic {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: 20% 10%;
}
