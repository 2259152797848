body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d487d4;
  width: 7px;
  border-radius: 20px;
  height: 7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading-home {
  font-size: 34px;
  line-height: 32px;
  font-weight: 600;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px;
  }
}
.heading-home1 {
  font-size: 34px;
  line-height: 32px;
  font-weight: 600;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px;
  }
  @media (max-width: 576px) {
    margin-bottom: 8px;
  }
}
/********** Sign up *********/
.signupImg {
  width: 75%;
  margin-left: 180px;
  margin-top: 36px;
}
.signupImg2 {
  width: 85%;
  position: absolute;
  height: 100%;
  bottom: 30px;
  right: 0px;
}
.signupImg3 {
  width: 85%;
  position: absolute;
  height: 100%;
  bottom: -184px;
  right: 0px;
}
.formData {
  width: 100%;
  padding-right: 10%;
}
.signupHead {
  color: rgb(196, 115, 196);
  margin-top: 15px;
  margin-bottom: 10px;
}
.signupHead1 {
  color: rgb(129, 124, 129);
  margin-top: 5px !important;
  margin-bottom: 30px;
}
.signupLogo {
  width: 175px;
  height: 65px;
  margin-left: -10px;
  margin-top: 50px;
  margin-bottom: 6px;
}
label.lableHr {
  width: 100% !important;
  font-family: -webkit-pictograph;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  white-space: nowrap;
  display: inline-block;
}
input.inputBox {
  width: 100%;
}
input.registerInputBox {
  width: 130%;
}
span.ant-input-affix-wrapper {
  border-radius: 5px;
}
span.inputBox .ant-input {
  height: 30px !important;
}
input.inputBox:focus {
  border: solid 1px #a64fa6;
  box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  -webkit-box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  -moz-box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}
button.buttonTxt {
  background: none;
  color: rgb(166, 79, 166);
  border: none;
  background-color: none;
  position: absolute;
  top: 28px;
  right: 0px;
  line-height: 38px;
  padding-right: 20px;
}
button.themeBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  color: #ffffff;
  border: none;
  font-weight: 600;
}
button.cancelBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%); */
  background: #ffffff;
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  /* color: #ffffff; */
  color: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border: none;
  font-weight: 600;
  /* Gradient text */
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
button.cancelBtn:hover {
  /* Ensure gradient text remains on hover */
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
/* button.cancelBtn:hover {
  background: #ffffff;
  color: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
} */
/* button.disabled.cancelBtn {
  opacity: 0.7;
}
button.disabled.cancelBtn:hover {
  background: #ffffff;
  color: #c473c4;
} */
button.themeBtnOvulation {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  color: #ffffff;
  border: none;
  font-weight: 600;
  @media (max-width: 374px) {
    font-size: 12px;
    line-height: 16px;
  }
}
button.signInBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 500px;
}
button.signUpBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
}
button.signUpBtn2 {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 40%;
  margin-top: -10px;
}
button.signUpBtn3 {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 80%;
  margin-top: -10px;
}
.signInLink {
  margin-top: 10px;
  margin-left: 15px;
}
button.socialBtn {
  font-family: Poppins;
  /* height: 40px;
  line-height:25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #C473C4;
  color:#FFFFFF;
  border: none;
  font-weight: 600;
  width: 295px;
  margin-top: 10px; */
}

button.themeBtnAppointment {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 40px 10px 40px;
  font-size: 12px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  /* text-align: left; */
}

button.themeBtn:hover {
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: #ffffff;
}
button.disabled.themeBtn {
  opacity: 0.7;
}
button.disabled.themeBtn:hover {
  background: #c473c4;
  color: #ffffff;
}
button.themeButtonHeight {
  height: fit-content;
}

a.themeBtnLink {
  height: 40px;
  line-height: 20px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  text-decoration: none;
}
a.themeBtnLink:hover {
  background: #a64fa6;
  color: #ffffff;
}

.submitRspn2 {
  position: relative;
}
.submitRspn2 ul {
  margin: 0px;
  padding: 0;
}
.submitRspn2 ul li {
  list-style-type: none;
  display: inline-block;
}
.submitRspn2 ul li:last-child {
  margin-left: 15px;
}
.submitRspn2 ul li a {
  display: block;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px 40px;
  text-decoration: none;
  color: #000;
  width: 154%;
  margin-left: -16px;
  margin-top: 10px;
}
.submitRspn3 ul li a {
  display: block;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px 40px;
  text-decoration: none;
  color: #000;
  width: 83%;
  margin-bottom: 20px;
}
.errorMsg {
  width: 100%;
}
.submitRspn2 ul li a svg {
  font-size: 1.2rem;
  margin-top: -5px;
  margin-right: 10px;
}
.submitRspn2 ul li a:hover {
  outline: none;
  border-color: #a64fa6;
}
.ant-form-item-has-feedback.ant-form-item-has-success
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating
  .ant-form-item-children-icon {
  top: 40% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}
.loginImg {
  width: 85%;
}

.welcome {
  font-family: Poppins !important;
  font-weight: bold;
  margin-bottom: 2px;
}
.formPadding {
  padding: 0px !important;
  width: 115%;
}
.formPadding1 {
  padding: 0px !important;
  margin-top: -25px !important;
}
.genderInliine label {
  position: relative;
  width: 100%;
  padding-top: 20px;
  height: 90px !important;
  text-align: center;
  box-shadow: 1px 6px 22px #ede8ed;
  border-style: none;
  border-radius: 10px !important;
}
.ant-radio-group {
  display: block !important;
}
.radioLbl {
  color: #000 !important;
  margin-top: 80px;
}
.datePickerlbl {
  position: relative;
}
.datePicker {
  width: 40%;
}
.radioInterest {
  width: 80%;
}
.radioLb {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
/************** doctor ******************/
.doctorHeadPrent {
  width: 100%;
  margin-top: 10%;
  font-size: 32px;
  line-height: 40px;
  color: #1f274a;
  font-weight: 600;
}
.doctorHeadChild {
  width: 100%;
  margin-top: 1%;
  font-size: 48px;
  line-height: 60px;
  color: #1f274a;
  font-weight: 600;
}
.carouselWWrap {
  width: 55% !important;
  margin: 0px auto;
}
.carouselWWrapDr {
  width: 50% !important;
  margin: 0px auto;
}
.doc-img {
  padding: 10% 0% 0% 5%;
  border-radius: 50px !important;
}
.doctorHeadSlide {
  width: 100%;
  margin-top: 1%;
  font-size: 32px;
  line-height: 50px;
  color: #1f274a;
  font-weight: 600;
  text-align: center;
}
.doc-info {
  padding: 30px 0px;
}
.doctorParaInline {
  width: 100%;
  margin: 0px auto;
  position: relative;
  text-align: center;
}
.doctorParaInline ul {
  margin: 0px;
  padding: 0px;
}
.doctorParaInline ul li {
  display: inline;
  float: left;
  font-size: 20px;
  list-style-type: none;
  padding: 0px 5px;
  color: #1f274a;
}
.doctorIcons svg {
  font-size: 18px;
  margin-top: -7px;
}
.carousel-control-prev,
.carousel-control-next {
  /* background: #FFF !important; */
  top: 35% !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 0px;
  opacity: 1 !important;
  position: absolute;
}
.carousel-control-next {
  right: 10px !important;
  visibility: hidden;
}
.carousel-control-prev {
  left: 10px !important;
  visibility: hidden;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 1.4rem !important;
}
.doctorCardTitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
#drTestimonials {
  position: relative;
  width: 60%;
  margin: 0px auto;
  background: none;
  padding-top: 100px;
}
#drTestimonials .carousel {
  background: none;
  box-shadow: none;
  border-radius: none;
}
#drTestimonials .customslides {
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 28px;
  border: 1px solid #a64fa6;
  padding: 6%;
}

#drTestimonials p {
  font-size: 18px;
  color: #a64fa6;
}
#drTestimonials .carousel-control-next,
#drTestimonials .carousel-control-prev {
  display: none !important;
}
.drtestmonialHead {
  font-family: Poppins;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
  color: #a64fa6;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
}
.carousel-indicators .active {
  background-color: #a54ea5;
  width: 22px;
  border-radius: 6px;
  height: 6px;
}
.carousel-control-next-icon {
  filter: invert(50%);
}

.carousel-control-prev-icon {
  filter: invert(50%);
}
.doctorRatings {
  position: relative;
  margin: 5px 0px;
}
.doctorRatings i {
  color: #f6c143;
  margin: 0px 2px;
  font-size: 1rem;
}
.doctorRightBg {
  width: 30%;
  position: absolute;
  right: 0;
  top: 35%;
}
.doctorRightBg1 {
  width: 30%;
  position: absolute;
  right: 0;
  top: 35%;
  height: 100vh;
}
.doctorLeftBg {
  width: 30%;
  position: absolute;
  left: 0;
  top: 90%;
}
/******* Footer ************/
.footerWrap {
  overflow: hidden !important;
  position: relative;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}
.footerHead1 {
  font-family: Poppins;
  font-size: 19px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
.compy {
  font-family: Poppins;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin: 15px 0px;
}
.footerPara1 {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
}
.footerParaPhone {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
}
.footerParaPhone i,
.footerParaList i {
  color: #ffffff;
  font-size: 14px;
  margin-right: 5px;
  line-height: 21px;
}
.footerParaList {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  float: left;
  margin-right: 15px;
}
.divderHr {
  height: 1px;
  background: #ffffff;
}
.footerHead2 {
  font-family: Poppins;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
ul.footerLinks {
  margin: 0px;
  padding: 0px;
}
ul.footerLinks li {
  display: list-item;
  list-style-type: none;
  color: #ffffff;
  margin: 5px 0px;
}
ul.footerLinks li a {
  display: inline-block;
  text-decoration: none;
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
}
button.footerBtn {
  line-height: 40px;
  width: 100%;
  font-size: 22px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #c473c4;
  background: #ffffff;
  border: none;
  text-align: center;
  margin: 10px 0px;
}
button.footerBtn:hover {
  background: #a64fa6;
  color: #ffffff;
}

.footerSocialList i {
  color: #a54ea5;
  font-size: 1.2rem;
  margin-right: 5px;
  line-height: 21px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
}
.footerSocialList i:hover {
  transform: scale(1.2);
  transition: 0.3s all;
}
.footerSocialList {
  float: left;
  margin-right: 5px;
  text-align: center;
}
.clearRow {
  clear: both;
}
.xyzTest {
  position: absolute;
  width: 100%;
  background: #ccc;
}
/**************** Header ***************/
.webWeader {
  /* width: 85%; */
  width: 100%;
  position: fixed;
  /* left: 8%; */
  /* top: 16px; */
  top: 0px;
  z-index: 999;
}
#navbarToggler {
  /* height: 60px; */
  @media only screen and (min-width: 1199.99px) {
    /* height: auto !important; */
    height: 60px;
  }
}
.drHeader {
  width: 100%;
  background: #ffffff;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  /* border-radius: 20px; */
  padding: 15px !important;
  /* @media only screen and (max-width: 1199.99px) {
		/* height: auto !important; */
  /* background: #fff; */
}
*/ .navbar-nav {
  margin: 0px 10px !important;
  /* @media only screen and (max-width: 1199.99px) {
		background: #fff !important;
	} */
}
.nav-link {
  color: #1f274a !important;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin: 0px 20px !important;
}
.nav-link:hover {
  color: #d487d4 !important;
}
.navRight {
  position: absolute;
  right: 15px;
}
.navbar-toggler {
  color: #fff !important;
  border: none;
  outline: none;
  border-color: #a54ea5 !important;
}
.navbar-toggler i {
  color: #a54ea5 !important;
}
.navbar-light .navbar-toggler-icon {
  color: #a54ea5 !important;
}
/************** Home page ********/
.headerAlign {
  margin: 0% 10% 0% 10%;
}
.homeWrap {
  width: 100%;
  position: relative;
  padding-top: 12%;
  background: #fff;
}
.homeheading {
  font-size: 3rem;
  line-height: 4rem;
  font-family: Poppins;
  font-size: 56px;
}
.homeTopBanner {
  margin-top: -30px;
}
.homeTopBannerMobile {
  display: none;
}
.homePlayStore {
  position: relative;
  padding-left: 5%;
  margin-top: 30px;
}
.iosPlayStore {
  position: relative;
  padding-left: 5%;
  margin-top: 30px;
}
.nonHomePlayStore img,
.nonIosPlayStore img {
  margin: 20px 0px;
}
.baby-focus-main {
  width: 12% !important;
  padding-left: 5%;
}
.baby-focus-1 {
  width: 10% !important;
  padding-left: 5%;
}
.baby-focus-2 {
  width: 8% !important;
  padding-left: 5%;
}
.baby-focus-3 {
  width: 6% !important;
  padding-left: 5%;
}
.baby-focus-4 {
  width: 4% !important;
  padding-left: 5%;
}
.homePregaCurveBg {
  width: 100%;
  position: fixed;
  margin-top: -30px;
  padding: 3% 0% 3% 0%;
  height: fit-content;
  background: linear-gradient(90deg, #d487d4 0%, #a54ea5 100%);
}
.homeCurveBgWrap {
  width: 100%;
  padding: 0% 8% 0% 8%;
  position: relative;
}
.homeCurveBg {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -60%;
}
.homePregaCurveBg,
.homeCorveData {
  width: 100%;
  position: absolute;
}
.homeCorveData {
  z-index: 10;
  width: 100%;
  position: relative;
}
.homepregBtm {
  padding: 3% 0% 0% 2%;
}
.homepregHeading {
  font-size: 1.5rem;
  color: #fff;
  font-family: Poppins;
  padding-left: 13%;
}
.homeWeekTitie {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  font-family: Poppins;
}
.btnClear {
  display: inline-block;
  vertical-align: top;
}
.homeCureHead {
  font-size: 2rem;
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
}
.homeCureHead2 {
  margin-top: 40px;
  font-size: 1.5rem;
  color: #fff;
  font-family: Poppins;
}
.homeCureHead2 span {
  font-size: 2rem;
  font-weight: 600;
}
.homeCardPrgaImg {
  width: 100%;
}
.homeCardPrgaPara h3 {
  color: white;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  margin-top: 15px;
}
.homeCardPrgaPara h3 span {
  color: white;
  font-size: 22px;
  line-height: 22px;
  font-weight: normal;
}
.homeCardPrgaPara span {
  color: white;
  font-size: 22px;
  line-height: 22px;
}
.homeSmilyWrap {
  background: #f9fbfe;
  padding-top: 700px;
  padding-bottom: 100px;
}
.smilyCont {
  width: 80%;
  margin: 0px auto;
  position: relative;
  background: #fff;
  padding: 30px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}
.smilyHeading {
  margin-bottom: 30px;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}
.smilyPara {
  margin-top: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
}
.feelingPointer {
  cursor: pointer;
}
.feelingPointer:hover {
  transform: scale(1.3);
  transition: 0.3s all;
}
.homeRightBg {
  width: 30%;
  position: absolute;
  right: 0;
  margin-top: -10%;
}
.weeklyHeWrap {
  width: 100%;
  position: relative;
  margin-top: 200px;
}
.weeklyHeWrap2 {
  width: 100%;
  position: relative;
}
.weeklyHeWrapBg {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
}
.pregaHeading {
  margin: 50px 0px 0px 0px;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}
.homeBabyHeading1 {
  color: white;
  text-align: right;
  padding-right: 32px;
  font-size: 64px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
}
.homeBabyHeading2 {
  color: #1f274a;
  text-align: left;
  padding-left: 32px;
  font-size: 64px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
}
ul.weeklyList {
  margin: 0;
  padding: 0;
}
ul.weeklyList li {
  display: list-item;
  list-style-type: none;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  background: url("images/card_icon.svg") left top no-repeat;
  padding: 0px 20px 0px 40px;
  margin: 0px 20px;
  margin-bottom: 20px;
}
ul.weeklyList2 li {
  background: url("images/card_icon2.svg") left top no-repeat !important;
  color: #1f274a;
}

.curatedWrap {
  width: 100%;
  position: relative;
  padding: 10% 0% 0% 0%;
  /* padding-left: 135px; */
}

.containerLeftPara {
  position: absolute;
  top: 12%;
  right: 40%;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.containerLeftParaVideo {
  position: absolute;
  top: 12%;
  /* right:40%; */
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.containerRightPara {
  position: absolute;
  bottom: 10%;
  left: 40%;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.curatedImg {
  width: 60%;
  border-radius: 30px;
  padding: 3%;
}
.curatedTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-top: 10%;
  margin-bottom: 30px;
}
.homeCuratedPara {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  color: #1f274a;
}
.homeRightBg {
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 10%;
}
.homeVides {
  margin-top: 7%;
}
.homeVideoLeft {
  top: 12%;
  bottom: auto;
  /* right:25%; */
  left: 40%;
}
.homeVideoRight {
  bottom: 12%;
  top: auto;
  left: 0%;
  right: 40%;
}
.videoPlayBtn {
  position: absolute;
  bottom: 55%;
  left: 40%;
}
.videoPlayLeftBtn {
  position: absolute;
  bottom: 40%;
  left: 25%;
}
.videoPlayRightBtn {
  position: absolute;
  bottom: 40%;
  right: 28%;
}
/* .video-right{
  margin-top: -90px; 
  margin-right: 48px;
  @media (min-width: 768px) and (max-width: 1024px){ 
    margin-top: -72px;
    margin-right: 32px;
 }
} */
.yourHelthBg {
  width: 100%;
  position: relative;
  padding-bottom: 50px;
}
.yourHelthWrap {
  width: 100%;
  position: relative;
  padding-top: 5%;
}
.yourHelthImg {
  width: 100%;
  position: absolute;
}
.yourHelthImg2 {
  position: absolute;
  top: 70%;
  left: 0;
  width: 30%;
}
.yourHelthImg3 {
  width: 100%;
  position: absolute;
  padding-top: 120px;
}
.homeHelthHead {
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 16px;
}

.helthboxTiles {
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 60px 10px 20px 10px;
  margin-bottom: 50px;
}
.helthboxHeading {
  margin: 30px 0px 15px 0px;
  font-family: Poppins;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #1f274a;
}
.helthboxPara {
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  color: #857085;
}
.homeHelthRightBg {
  position: absolute;
  width: 30%;
  right: 0;
  top: -20%;
}
.atEasySpace {
  width: 100%;
  position: relative;
  padding: 5% 0px 0px 0px;
}
.atEasyBg {
  position: absolute;
  width: 100%;
  top: -30px;
}
.atEasylogoSpace {
  margin-bottom: 2%;
}
/********** added 21.10.22 ********/
.atEasyCustomTile {
  margin-top: -40%;
}
.atEasyboxTiles {
  /* width: 100%; */
  position: relative;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 40px 10px;
  /* margin-bottom: 50px; */
  cursor: pointer;
  margin-bottom: 10%;
}
.homeLinkBg {
  /* background: url('images/homeLinkbg.svg') center center no-repeat !important; */
  height: 300px;
  padding: 20px 0px;
  margin-bottom: 30px;
  @media (min-width: 768px) and (max-width: 1280px) {
    height: fit-content;
    padding: 0px;
    margin-bottom: 0px;
  }
}
.EasyIconPara {
  padding-top: 30px;
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
  color: #1f274a;
  font-weight: 600;
}
.homeLinkBg a {
  text-decoration: none;
}
button.modalLinkBtn:focus,
button.modalLinkBtn {
  border: none;
  box-shadow: none;
  outline: none;
}
#homeTestimonials {
  position: relative;
  width: 60%;
  margin: 0px auto;
  background: none;
  padding-bottom: 100px;
}
#homeTestimonials .carousel {
  background: none;
  box-shadow: none;
  border-radius: none;
}
#homeTestimonials .customslides {
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 28px;
  border: 1px solid #a64fa6;
  padding: 5%;
}

#homeTestimonials p {
  font-size: 18px;
  color: #a64fa6;
}
#homeTestimonials .carousel-control-next,
#homeTestimonials .carousel-control-prev {
  display: none !important;
}
.carousel-indicators li {
  background-color: #a54ea5;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  cursor: pointer;
  bottom: -50px;
  /* border: 1px solid #A54EA5 */
}

.homeModalBorder .ant-radio-button-wrapper {
  border: 1px solid #a3a9bf;
}
.homeModalBorder .ant-radio-button-wrapper:first-child {
  border-color: #a3a9bf;
}
.homeModalBorder .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.nonPregHomeSpace {
  padding-bottom: 250px;
}
.nonPregHomeBanner {
  position: absolute;
  right: 0;
  width: 100%;
  top: 3%;
}
/**** Listing page ***********/
.listSpacing {
  margin-top: 150px;
  margin-bottom: 80px;
}
.listHeading {
  position: relative;
  width: 100%;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
}
.main-img {
  width: 100%;
  border-radius: 10px;
}
.listDatePara {
  font-family: Poppins;
  font-size: 16px;
}
.listingDot {
  font-size: 4px;
  margin: 0px 15px;
  color: #a3a9bf;
}
.listIcons {
  font-size: 1.5rem;
  margin-left: 10px;
}
.listingImgPara {
  margin-top: 36px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #1f274a;
}
.sideCard {
  width: 100%;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  border-radius: 10px;
  margin-bottom: 30px;
}
.sideCard img {
  width: 100%;
}
.listSideWrap {
  width: 100%;
  padding: 20px 15px;
}
.ListSideHead {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #1f274a;
}
.listBottomLeftBg {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.listLeftBg {
  position: absolute;
  left: 0;
  bottom: -80%;
  width: 28%;
  z-index: 0;
}
.listRightBg {
  width: 20%;
  position: absolute;
  right: 0%;
  top: 25%;
}
/********* Doctor Listing **********/
.expertWrap {
  margin-top: 100px;
  position: relative;
  width: 100%;
  /* @media(min-width: 1200px){
    margin-top: 110px;
  } */
}
.expertHeading {
  position: relative;
  width: 100%;
  font-family: Poppins;
  font-size: 34px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  margin-bottom: 30px;
}
.expertAlign {
  text-align: right;
}
.consultSearch {
  border-radius: 25px !important;
  margin-top: 2%;
  width: 45% !important;
}
.repotSearch {
  border-radius: 25px !important;
  margin-top: 2%;
  width: 25% !important;
}
@media only screen and (max-width: 480px) {
  .repotSearch {
    width: 90% !important;
  }
}
@media only screen and (max-width: 600px) {
  .repotSearch {
    width: 90% !important;
  }
}
.drListingfilter {
  margin-left: 2%;
}
.listImgWrap {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
}
.listExpImg {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.listexpertBox {
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  margin-bottom: 30px;
}
.listBoxHeader {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #1f274a;
  margin: 0px 0px 10px 0px;
}
.listBoxPara {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a3a9bf;
  margin-bottom: 10px;
}
.drListLeftBg {
  width: 30%;
  position: absolute;
  bottom: -10;
  left: 0;
}
.drListLeftBg {
  position: absolute;
  bottom: 0%;
  left: 0;
}
.drListRightBg {
  position: absolute;
  top: 5%;
  right: 0;
}
.pageWrap {
  width: 100%;
  position: relative;
  padding: 150px 0px;
}
.pageWrap2 {
  width: 100%;
  position: relative;
  padding: 65px 0px;
}
.pageWrap3 {
  width: 100%;
  position: relative;
  /* padding: 110px 0px; */
  padding: 100px 0px;
}
.pageWrap4 {
  width: 100%;
  position: relative;
  padding: 120px 0px;
}
.dtDetailsHead {
  font-size: 34px;
  line-height: 72px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 600;
  text-align: left;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    /* margin-top: 24px; */
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .dtDetailsHead {
    font-size: 24px;
    line-height: 72px;
    font-family: Poppins;
    color: #1f274a;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 16px;
  }
}
.drDetailsImgRound {
  border-radius: 30px;
}

.drDetailsHeading2 {
  margin: 0;
  font-size: 48px;
  line-height: 60px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 600;
}

.drDetailsPara1 {
  position: relative;
  width: 100%;
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
  color: #1f274a;
  margin-bottom: 5px;
}

.drDetailsHeading3 {
  position: relative;
  width: 100%;
  font-size: 32px;
  line-height: 60px;
  font-weight: 600;
  font-family: Poppins;
  color: #1f274a;
}

.drlistImgRightWrapLeft {
  float: left;
  text-align: left;
  width: 10%;
  @media (max-width: 768.99px) {
    width: 15%;
    text-align: center;
  }
}

.drlistImgRightWrapRight {
  float: right;
  text-align: left;
  width: 90%;
  @media (max-width: 768.99px) {
    width: 85%;
  }
}
.ht-100 {
  height: 100%;
}
.drDetailsRightBoxWrap {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 20px 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }
}
.drDetailsRightBoxWrap2 {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 20px 30px;
  margin-bottom: 30px;
  @media (max-width: 768.99px) {
    padding: 15px;
  }
}

.drDetailsRightBox2 {
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
}

.drDetailsRightHead {
  margin: 0;
  font-family: Poppins;
  color: #1f274a;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
}

.drDetailsRightHead2 {
  margin-left: 0;
  font-family: Poppins;
  color: #a3a9bf;
  font-size: 12px;
  line-height: 18px;
  /* word-break: break-all; */
}

.drDetailsInfo {
  margin-left: 10px;
}

.m-left {
  margin-left: 10px;
}

.nonWeeklyHeWrap {
  width: 100%;
  padding-top: -20% !important;
  position: relative;
}
/* interest Page */
.ant-modal-content {
  border-radius: 20px !important;
}
/********** Scroll ************/
.dateSlotBorder::before {
  background: none !important;
}

.themeScroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #e9eff9;
}

.themeScroll::-webkit-scrollbar {
  width: 3px;
  background-color: #d4e4f8;
}

.themeScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}
/************************/
.drReadBox {
  border-radius: 30px;
  padding-bottom: 30px;
}

.drReadImgRound {
  border-radius: 30px;
  width: 100%;
  min-height: 350px;
  max-height: 350px;
}

.drReadHeading {
  margin: 15px 0px;
  font-family: Poppins;
  color: #1f274a;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #1f274a;
}

.drReadComment {
  font-size: 16px;
  font-family: Poppins;
  color: #1f274a;
}

.drArticleRound {
  border-radius: 10px;
  width: 100%;
  max-height: 700px;
}
/***Support Block***/
.BlockTitle {
  font-family: Poppins;
}

.ant-form-item-explain-error {
  padding-left: 50px;
}

.listSupportImg {
  border-radius: 20px !important;
  min-height: 330px;
  max-height: 330px;
  width: 100% !important;
}

.themBtn:focus,
.themBtn:active {
  background: none !important;
}

.invisiLink {
  text-decoration: none;
  font-size: 19px;
  font-family: Poppins;
}
/* appointment list page */
.apptListBox {
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  margin-bottom: 30px;
  position: relative;
}

.apptListHeader {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  color: #1f274a;
}

.profileEdit {
  position: absolute;
  bottom: 0;
  right: 35%;
}

.profileHeading2 {
  font-size: 1rem;
  line-height: 2rem;
  font-family: Poppins;
  font-weight: 600;
  margin: 50px 0px 25px 0px;
}

.profileRadioImg {
  padding: 10px;
}

.profileRadioLble {
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 5%;
}

.profileRadioLbleLarge {
  font-size: 1.2rem;
  line-height: 1rem;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 4%;
}

.profileRightBg {
  width: 20%;
  position: absolute;
  right: 0;
  top: 10%;
}
.profileRightBg1 {
  width: 20%;
  position: absolute;
  right: 0;
  top: 10%;
  height: 0vh;
}
.profileLeftBg {
  width: 40%;
  position: absolute;
  left: 0;
  bottom: -10%;
}
.profileLeftBg1 {
  width: 40%;
  position: absolute;
  left: 0;
  bottom: -10%;
  height: 0vh;
}
button.Supportbtn {
  height: 40px;
  line-height: 20px;
  padding: 6px 30px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: #ffffff;
  border: none;
  text-decoration: none;
  outline: none;
}

.supportTribeSelect {
  float: right;
}

.supportTribeTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 30px;
}

.drNotificationIcon svg {
  font-size: 20px;
  margin-top: -10px;
}

.drConsltBookinWrap {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 30px;
  margin-bottom: 30px;
}

.ConstBookTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 30px;
  line-height: 30px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

input.cbTextBorder {
  border-radius: 10px !important;
}

.cbTextBorder .ant-select-selector {
  border-radius: 10px !important;
}

.cbTextBorder .ant-picker-large {
  border-radius: 10px !important;
}

.bookTestBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
}

.bookTestBox p {
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  color: #1f274a;
  font-weight: 400;
  text-align: left;
}

.appointmentBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 5px 60px 5px !important;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
}

.boolTestHead {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1f274a;
  margin-bottom: 25px;
}

.boolTestHeade2 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1f274a;
  margin-bottom: 10px;
}

.bookReadMore {
  text-decoration: none;
  font-family: Poppins;
  font-weight: 600;
  color: #c473c4;
  font-size: 14px !important;
}

.toolBoxLabel {
  font-size: 16px;
  font-weight: 600;
}

.customDropDown {
  padding: 8px 10px !important;
  border: 1px solid #ccc;
  border-radius: 10px !important;
  font-size: 14px;
  box-shadow: none !important;
}

.customDropDown:hover,
.customDropDown:focus {
  border-color: #a64fa6 !important;
}

.calCalendar {
  width: 100% !important;
  border: none !important;
}

button.testColor {
  background-color: green;
}

button.testColor2 {
  background-color: red;
}

button.testColor3 {
  background-color: yellow;
}

.rdrNextPrevButton {
  background: none !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrMonth,
.rdrCalendarWrapper {
  width: 100% !important;
  font-size: 14px;
  line-height: 1.125em;
  color: #a0a096;
}

.rdrMonth,
.rdrCalendarWrapper {
  width: 100% !important;
  font-size: 16px;
  line-height: 1.125em;
  color: #a0a096;
}

.rdrMonths,
.rdrMonthAndYearPickers {
  pointer-events: none;
}

.rdrInRange,
.rdrStartEdge {
  color: #c473c4 !important;
}

.rdrEndEdge {
  color: #a54ea5 !important;
}

.reportTabWrap {
  padding: 0px 30px 15px 30px !important;
  border-radius: 15px;
}

.reportTabWrap .ant-tabs-tab {
  font-weight: 600;
  font-size: 16px;
  padding: 15px 30px;
}

.reportTabWrap .ant-tabs-tab-active {
  background: #c473c4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.reportTabWrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

.reportBox {
  width: 100%;
  padding: 30px;
  position: relative;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}

.reportDownload {
  font-size: 1.7rem;
  color: #a54ea5;
  cursor: pointer;
}

.reportTabWrap .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.reportTabWrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: static !important;
  overflow: auto !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: none !important;
}

.reportWrap {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.reportingView {
  border-radius: 15px;
  /* height: 100px;
  width: 50px; */
}

.errorInput {
  color: #ff4d4f;
}

.reportDelete {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #a54ea5;
  color: #ffffff;
  border: none;
  border-radius: 50%;
}

.reportDelete svg {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.reportFileName {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  overflow: hidden;
  padding: 5px 10px;
  background-color: #c473c4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

#pic {
  display: none;
}

.lablePic {
  width: 40%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  transition: all 0.5s;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  @media (max-width: 320px) {
    font-size: 14px;
  }
  @media (max-width: 280px) {
    font-size: 12px;
  }
}

.lablePic:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.myReportAddWrap {
  position: fixed;
  width: 20% !important;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.myReportAdd {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: white;
  font-size: 2rem;
  font-family: "Poppins";
  text-align: center;
  padding: 1rem;
  font-weight: 600;
  line-height: 22px;
  outline: none;
  border: none;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

ul.fellingList {
  margin: 5% 0% 2% 4%;
  padding: 0;
}

ul.fellingList li {
  font-family: "Poppins";
  color: #1f274a;
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 600;
  margin: 15px 0px;
}

.fellingLink {
  font-size: 0.98rem;
  line-height: 0.98rem;
  font-family: "Poppins";
  color: #a64fa6;
  font-weight: 600;
  text-decoration: underline !important;
}

.fellingLink:hover {
  color: #c473c4;
  text-decoration: none !important;
}

.file_upload {
  opacity: 0;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;
  /* older Safari browsers */
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.upload-img-ConsultBook {
  position: absolute;
  top: 45%;
  margin-left: -55%;
}

.consultReportBox {
  width: 100%;
  padding: 30px;
  position: relative;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}
/* .consultReportBox:cl */

.nonSmilyMargin {
  margin-top: 20%;
}

.giftLink {
  cursor: pointer;
}

.giftBox {
  border-radius: 12px;
  box-shadow: 0px 8px 18px rgba(155, 162, 171, 0.3);
  /* background-color: #C473C4; */
  border-color: #c473c4;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.couponBox {
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(155, 162, 171, 0.3);
  height: 430px;
  overflow-y: scroll;
}

/******Gift scroll*********/
.couponScroll::-webkit-scrollbar {
  width: 3px;
  height: 50px;
}

.couponScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.couponScroll::-webkit-scrollbar-thumb {
  background: #888;
}

.couponScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.expertDetail {
  margin: 0px;
}

.loginRightBg {
  /* margin: 150px; */
  height: 50%;
  width: 50%;
  margin-right: 45%;
}

.loginImg1 {
  width: 90%;
  margin-top: 91px;
  margin-right: 45px;
}

.button {
  margin-left: 130px;
}

@media only screen and (max-width: 768px) {
  .myReportAddWrap {
    width: 50% !important;
  }
}

.navbar-toggler {
  margin-inline: 20px;
}

@media (max-width: 281px) {
  .footerSocialList i {
    width: 25px;
    height: 25px;
    line-height: 16px;
    text-align: center;
    font-size: 0.9rem;
  }
}
#quickAccroding .ant-collapse-icon-position-left {
  margin-bottom: 15px !important;
}
#quickAccroding img {
  width: 100%;
  height: auto;
}
/**************** Mobile menu *******/
#overlayCloseRight {
  display: none;
}

/********** Hide input type number arrow 03.05.23 ************/

.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
/* Works for Firefox */
.inputNumber input[type="number"] {
  -moz-appearance: textfield !important;
}

/********************* Set container width as devise wise ***************************/
@media (min-width: 2200px) {
  .container {
    max-width: 1800px !important;
  }
}
@media (max-width: 1800px) {
  .container {
    max-width: 1600px !important;
  }
  .navRight {
    float: right !important;
  }
}
@media (max-width: 1600px) {
  .container {
    max-width: 1400px !important;
  }
}
@media (max-width: 1500px) {
  .container {
    max-width: 98% !important;
  }
}
@media (max-width: 1330px) {
  .navRight {
    float: right !important;
  }
  .webWeader .navbar-expand-xl {
    width: 100% !important;
    padding: 15px !important;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0px !important;
  }
}
@media (max-width: 1199px) {
  .navRight {
    float: none !important;
  }
}
@media (max-width: 768px) {
  .webWeader .navbar-expand-xl {
    padding: 15px 0px !important;
  }
}
.headerNavigation li,
.navbar-nav li {
  margin: 5px 0px 0px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px 15px 0px !important;
  }
}

.activePage {
  color: #a54ea5 !important;
}

.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 32px;
  z-index: 99;
  @media (max-width: 280px) {
    font-size: 16px;
    right: 20px;
  }
}

.ant-drawer-content-wrapper {
  z-index: 100;
}
.ant-spin-dot-item {
  background: #a54ea5 !important ;
}

.ant-spin-text {
  color: #a54ea5 !important ;
}

.img-animation {
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.resourceImg:hover {
  transform: scale(1.2);
  transition: 0.3s all;
}

.top-space {
  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

/* .curated-img{
  height: 230px;
} */
.blog-create-date {
  color: #ce80ce;
  background: #e4cfe4;
  border-radius: 16px;
  padding: 8px;
  width: fit-content;
  font-size: 12px;
}

.curated-img {
  box-shadow: 1px 6px 22px #ede8ed;
}
.curated-img:hover {
  transform: scale(1.05);
  transition: 0.3s all;
}

.loader-container {
  height: 500px;
}

.loader-container2 {
  height: 250px;
}

.view-btn {
  width: fit-content;
}
.descriptionImg img {
  max-width: 100%;
  height: auto;
}
.display-mobile {
  display: none;
  @media (max-width: 1199.99px) {
    display: block;
  }
}

.display-laptop {
  display: block;
  @media (max-width: 1199.99px) {
    display: none;
  }
}

.header-pic {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.remove-underline {
  text-decoration: none;
}

.no-slot-text {
  font-size: 24px;
  font-weight: 500;
  height: 90px;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 45px;
  }
}
