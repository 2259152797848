#detail{
    padding-left: 8%;
    margin-top: -50%;
}

.docDetail-img{  
    border-radius: 25px;
    width: 115%;
}

.doc-detail-info{
    text-align: left;
    width: fit-content; 
    margin-left: 5%; 
}

.heading{
    font-size: 48px;
}






.jQOMdP {
    width: 265px;
    height: 243px;
}
#blob {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 50px;
    padding-left: 10%;
}

.blob-img{ 
    margin-right: 3%;
    margin-bottom: 2%; 
}

#blob-med{
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 130px; 
}

#big-blob{
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 350px; 
}

.videoinb{
    margin-right: 40%;
    height: 6%;
}

.in-blobs{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-top: 5%;
    padding-left: 10%;
}

.blob-info{
    padding-left: 20%;
    font-size: 12px;
}


 

@media (min-width: 300px) and  (max-width: 580px){
    .docDetail-img{
        margin-top: 15%;
        width: 130px;
        padding-left: 10%;
    }

    #detail{
        margin-top: 10%;
    }

    .doc-detail-info{
        font-size: 15px;  
        height: 80%;
        margin-top: 10%; 
        padding-left: 5%;
        justify-content: left;
        padding-bottom: 5%;
    }

    .doc-des{
        width: fit-content;
    }

    #blobs{
        margin-left: 12%;
        padding-right: 0%;
        width: fit-content;
    }

    .heading{
        font-size: 20px;
    }

    .heading1{
        display: none;
    }

     
}

@media (min-width: 550px) and  (max-width: 800px) {
    .heading1{
        margin-top: 18%;
    }
    
    .docDetail-img{
        margin-top: 8%;
        width: 130px;
        padding-left: 10%;
    }

    #detail{
        margin-top: -95%;
    }

    .heading{
        font-size: 30px;
    }

    #blob{
        width: 100%;
    }

    #big-blob{
        width: 100%;
        height: 320px;
    }

    #blob-med{
        width: 100%;
        height: 150px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {

}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    .dtDetailsHead, .drDetailsHeading2{
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
}