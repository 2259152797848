p span {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #1f274a;
}
#responsiveTable table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
}
#responsiveTable table p {
  margin: 0px;
  color: #1f274a;
}
#responsiveTable table td {
  padding: 5px 10px;
  border: 1px solid #ccc;
}
ul,
ol {
  margin: 0;
  padding: 0 0px 0px 15px;
}
ul li {
  margin: 0px 0px 15px 0px;
}
ul li span {
  font-size: 14px !important;
}
@media (min-width: 576px) {
  /* .articlePadding{
      padding: 6%;
      padding-top: 0;
      padding-bottom: 0;
    } */
  .articleViewsWidth {
    width: 59% !important;
  }
}
