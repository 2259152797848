.atEasyHoverImg {
  width: 100%;
  position: relative;
  background: #ffffff;
  padding: 5%;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  cursor: pointer;
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 150px;
    padding: 8%;
  }
  @media (min-width: 1024px) and (max-width: 1400px) {
    height: 180px;
  }
}
.atEasyHoverImg:hover {
  box-shadow: 4px 4px 2px #a54ea5;
  transform: scale(1.05);
  transition: 0.3s all;
}
.atEasyHoverImg svg path {
  fill: #1f274a;
  transition: all ease 0.3s;
}
.atEasyHoverImg:hover path {
  fill: #cf509b;
  transition: all ease 0.3s;
}

@media (max-width: 769px) {
  .atEasyHoverImg img {
    max-height: 45px;
  }
}
@media (max-width: 577px) {
  .atEasyHoverImg img {
    max-height: 100%;
  }
}
/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .atEasyHoverImg {
    margin-top: 15px;
  }
  .atEasyHoverImg img {
    max-height: 70px;
  }
}
