
.emojicontainer{
    display: flex; 
    height: 30%;
    padding: 2%;
  }
  
  .emoji1{ 
    width: auto;
    height: auto;
    text-align: center; 
  }

  .card1p-container{
    margin-top: 10%;
    position:absolute; 
    width:60%;
    margin-left:20%;
    display: flex;
    z-index: 1;
    justify-content: space-around;
  }
  
  .card2p-container{
      margin-top: 40%;
      position:absolute; 
      width:60%;
      margin-left:20%;
      z-index: 1;
      display: flex; 
      justify-content: space-evenly;
  }
  
  .card3p-container{
      margin-top:110%;
      position:absolute; 
      width:60%;
      margin-left:20%;
      z-index: 1; 
      display: flex;
      justify-content: space-evenly; 
  }
  
  .shortp-up{
      width: 160px;
      height: 80%;
      padding:60px;  
      align-items: center;
      justify-content: center;
      z-index: 1;
      border-radius: 20px;
      margin-top: -50px;
      box-shadow: 1px 6px 22px #EDE8ED; 
  }
  
  .emojicontainer{
    display: flex;
    justify-content: space-around;
    height: 30%;
    padding: 2%;
  }
  
  .emoji1{ 
    width: auto;
    height: auto;
    text-align: center;
  }
  
  .info1{ 
    width: auto;
    height: auto;
  }
  
  .shortp{ 
      width: 160px;
      height: 80%;
      padding:60px;  
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-shadow: 1px 6px 22px #EDE8ED;    
  }
   .card-img-top{
       margin-top: 2px;
      height: 60px;
      width : 100px; 
  }
   
  
  .shortp-exp{ 
    width: 190px;
    height: 250px;
    padding:60px;  margin-left: 15px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 1px 6px 22px #EDE8ED;    
  }
  
  .card-img-top-exp{
     margin-top: 2px;
    height: 60px;
    width : 100px; 
  }

  @media (min-width: 800px) and (max-width: 1024px){
  
    .shortp{ 
      width: 140px;
      height: 60%;
      padding:50px; 
    }
  
    .shortp-up{ 
      width: 140px;
      height: 60%;
      padding:50px; 
    }
  
    .card-img-top{
      margin-top: 2px;
     height: 60%;
     width : 60px;
     margin-left: 0px; 
    } 
  
    .shortp-exp{ 
      width: 160px;
      height: 60%;
      padding:50px; 
    }
  
    .card-img-top-exp{
      margin-top: 2px;
     height: 60%;
     width : 60px;
     margin-left: 0px; 
    }
  
    .info1{
      margin-top: 13%;
    } 
  } 
  @media (min-width: 550px) and  (max-width: 800px){
       
    .shortp{ 
      width: 100px;
      height: 50%;
      padding:40px; 
    }
  
    .shortp-up{ 
      width: 100px;
      height: 50%;
      padding:40px; 
    }
  
    .card-img-top{
      margin-top: 3px;
     height: 50%;
     width : 90px;  
    }
  
    .shortp-exp{ 
      width: 120px;
      height: 50%;
      padding:40px; 
    }
  
    .card-img-top-exp{
      margin-top: 3px;
     height: 60%;
     width : 100px;  
    }
  
    .info1{
      margin-top: -13%;
    }
  } 
  @media (min-width: 300px) and  (max-width: 580px){
    .shortp{ 
      width: 60px;
      height: 30%;
      padding:30px; 
      flex-direction: column;
    }
  
    .shortp-up{ 
      width: 80px;
      height: 40%;
      padding:30px; 
      flex-direction: column; 
    }
  
    .card-img-top{
      margin-top: 25px;
      height: 80%;
      width : 90px;  
    }
  
    .shortp-exp{ 
      width: 80px;
      height: 30%;
      padding:40px; 
    }
  
    .card-img-top-exp{
      margin-top: 5px;
      /* height: 170%; */
      width : 90px; 
    }  
  
    .emoji1{
      width: 20px;
      height: 50px;
      text-align: center;
    }
  
    /* .ant-btn-round.ant-btn-lg{
      height: 20px;
    } */
  
    .info1{
      margin-top: -53%;
    }  
    .carousel-caption {
      top: 100;
      bottom: -25px;
    }
  
    .carousel-control-next {
      height: 170px;
      width: 6%; 
      margin-right: 2%;
    }
  
    .carousel-control-prev {
      height: 170px;
      width: 6%; 
      margin-left: 2%;
    }
   
  }
  