@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d487d4;
  width: 7px;
  border-radius: 20px;
  height: 7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading-home {
  font-size: 34px;
  line-height: 32px;
  font-weight: 600;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px;
  }
}
.heading-home1 {
  font-size: 34px;
  line-height: 32px;
  font-weight: 600;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px;
  }
  @media (max-width: 576px) {
    margin-bottom: 8px;
  }
}
/********** Sign up *********/
.signupImg {
  width: 75%;
  margin-left: 180px;
  margin-top: 36px;
}
.signupImg2 {
  width: 85%;
  position: absolute;
  height: 100%;
  bottom: 30px;
  right: 0px;
}
.signupImg3 {
  width: 85%;
  position: absolute;
  height: 100%;
  bottom: -184px;
  right: 0px;
}
.formData {
  width: 100%;
  padding-right: 10%;
}
.signupHead {
  color: rgb(196, 115, 196);
  margin-top: 15px;
  margin-bottom: 10px;
}
.signupHead1 {
  color: rgb(129, 124, 129);
  margin-top: 5px !important;
  margin-bottom: 30px;
}
.signupLogo {
  width: 175px;
  height: 65px;
  margin-left: -10px;
  margin-top: 50px;
  margin-bottom: 6px;
}
label.lableHr {
  width: 100% !important;
  font-family: -webkit-pictograph;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  white-space: nowrap;
  display: inline-block;
}
input.inputBox {
  width: 100%;
}
input.registerInputBox {
  width: 130%;
}
span.ant-input-affix-wrapper {
  border-radius: 5px;
}
span.inputBox .ant-input {
  height: 30px !important;
}
input.inputBox:focus {
  border: solid 1px #a64fa6;
  box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  -webkit-box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  -moz-box-shadow: 0 0 0 2px rgb(134 85 144 / 20%);
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}
button.buttonTxt {
  background: none;
  color: rgb(166, 79, 166);
  border: none;
  background-color: none;
  position: absolute;
  top: 28px;
  right: 0px;
  line-height: 38px;
  padding-right: 20px;
}
button.themeBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  color: #ffffff;
  border: none;
  font-weight: 600;
}
button.cancelBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%); */
  background: #ffffff;
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  /* color: #ffffff; */
  color: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border: none;
  font-weight: 600;
  /* Gradient text */
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
button.cancelBtn:hover {
  /* Ensure gradient text remains on hover */
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
/* button.cancelBtn:hover {
  background: #ffffff;
  color: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
} */
/* button.disabled.cancelBtn {
  opacity: 0.7;
}
button.disabled.cancelBtn:hover {
  background: #ffffff;
  color: #c473c4;
} */
button.themeBtnOvulation {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  /* CTA shadow */
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 42px;
  color: #ffffff;
  border: none;
  font-weight: 600;
  @media (max-width: 374px) {
    font-size: 12px;
    line-height: 16px;
  }
}
button.signInBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 500px;
}
button.signUpBtn {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
}
button.signUpBtn2 {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 40%;
  margin-top: -10px;
}
button.signUpBtn3 {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 80%;
  margin-top: -10px;
}
.signInLink {
  margin-top: 10px;
  margin-left: 15px;
}
button.socialBtn {
  font-family: Poppins;
  /* height: 40px;
  line-height:25px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #C473C4;
  color:#FFFFFF;
  border: none;
  font-weight: 600;
  width: 295px;
  margin-top: 10px; */
}

button.themeBtnAppointment {
  font-family: Poppins;
  height: 40px;
  line-height: 25px;
  padding: 6px 40px 10px 40px;
  font-size: 12px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  font-weight: 600;
  /* text-align: left; */
}

button.themeBtn:hover {
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: #ffffff;
}
button.disabled.themeBtn {
  opacity: 0.7;
}
button.disabled.themeBtn:hover {
  background: #c473c4;
  color: #ffffff;
}
button.themeButtonHeight {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

a.themeBtnLink {
  height: 40px;
  line-height: 20px;
  padding: 6px 70px 10px 70px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #c473c4;
  color: #ffffff;
  border: none;
  text-decoration: none;
}
a.themeBtnLink:hover {
  background: #a64fa6;
  color: #ffffff;
}

.submitRspn2 {
  position: relative;
}
.submitRspn2 ul {
  margin: 0px;
  padding: 0;
}
.submitRspn2 ul li {
  list-style-type: none;
  display: inline-block;
}
.submitRspn2 ul li:last-child {
  margin-left: 15px;
}
.submitRspn2 ul li a {
  display: block;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px 40px;
  text-decoration: none;
  color: #000;
  width: 154%;
  margin-left: -16px;
  margin-top: 10px;
}
.submitRspn3 ul li a {
  display: block;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px 40px;
  text-decoration: none;
  color: #000;
  width: 83%;
  margin-bottom: 20px;
}
.errorMsg {
  width: 100%;
}
.submitRspn2 ul li a svg {
  font-size: 1.2rem;
  margin-top: -5px;
  margin-right: 10px;
}
.submitRspn2 ul li a:hover {
  outline: none;
  border-color: #a64fa6;
}
.ant-form-item-has-feedback.ant-form-item-has-success
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating
  .ant-form-item-children-icon {
  top: 40% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}
.loginImg {
  width: 85%;
}

.welcome {
  font-family: Poppins !important;
  font-weight: bold;
  margin-bottom: 2px;
}
.formPadding {
  padding: 0px !important;
  width: 115%;
}
.formPadding1 {
  padding: 0px !important;
  margin-top: -25px !important;
}
.genderInliine label {
  position: relative;
  width: 100%;
  padding-top: 20px;
  height: 90px !important;
  text-align: center;
  box-shadow: 1px 6px 22px #ede8ed;
  border-style: none;
  border-radius: 10px !important;
}
.ant-radio-group {
  display: block !important;
}
.radioLbl {
  color: #000 !important;
  margin-top: 80px;
}
.datePickerlbl {
  position: relative;
}
.datePicker {
  width: 40%;
}
.radioInterest {
  width: 80%;
}
.radioLb {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
/************** doctor ******************/
.doctorHeadPrent {
  width: 100%;
  margin-top: 10%;
  font-size: 32px;
  line-height: 40px;
  color: #1f274a;
  font-weight: 600;
}
.doctorHeadChild {
  width: 100%;
  margin-top: 1%;
  font-size: 48px;
  line-height: 60px;
  color: #1f274a;
  font-weight: 600;
}
.carouselWWrap {
  width: 55% !important;
  margin: 0px auto;
}
.carouselWWrapDr {
  width: 50% !important;
  margin: 0px auto;
}
.doc-img {
  padding: 10% 0% 0% 5%;
  border-radius: 50px !important;
}
.doctorHeadSlide {
  width: 100%;
  margin-top: 1%;
  font-size: 32px;
  line-height: 50px;
  color: #1f274a;
  font-weight: 600;
  text-align: center;
}
.doc-info {
  padding: 30px 0px;
}
.doctorParaInline {
  width: 100%;
  margin: 0px auto;
  position: relative;
  text-align: center;
}
.doctorParaInline ul {
  margin: 0px;
  padding: 0px;
}
.doctorParaInline ul li {
  display: inline;
  float: left;
  font-size: 20px;
  list-style-type: none;
  padding: 0px 5px;
  color: #1f274a;
}
.doctorIcons svg {
  font-size: 18px;
  margin-top: -7px;
}
.carousel-control-prev,
.carousel-control-next {
  /* background: #FFF !important; */
  top: 35% !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 0px;
  opacity: 1 !important;
  position: absolute;
}
.carousel-control-next {
  right: 10px !important;
  visibility: hidden;
}
.carousel-control-prev {
  left: 10px !important;
  visibility: hidden;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 1.4rem !important;
}
.doctorCardTitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
#drTestimonials {
  position: relative;
  width: 60%;
  margin: 0px auto;
  background: none;
  padding-top: 100px;
}
#drTestimonials .carousel {
  background: none;
  box-shadow: none;
  border-radius: none;
}
#drTestimonials .customslides {
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 28px;
  border: 1px solid #a64fa6;
  padding: 6%;
}

#drTestimonials p {
  font-size: 18px;
  color: #a64fa6;
}
#drTestimonials .carousel-control-next,
#drTestimonials .carousel-control-prev {
  display: none !important;
}
.drtestmonialHead {
  font-family: Poppins;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
  color: #a64fa6;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
}
.carousel-indicators .active {
  background-color: #a54ea5;
  width: 22px;
  border-radius: 6px;
  height: 6px;
}
.carousel-control-next-icon {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}

.carousel-control-prev-icon {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}
.doctorRatings {
  position: relative;
  margin: 5px 0px;
}
.doctorRatings i {
  color: #f6c143;
  margin: 0px 2px;
  font-size: 1rem;
}
.doctorRightBg {
  width: 30%;
  position: absolute;
  right: 0;
  top: 35%;
}
.doctorRightBg1 {
  width: 30%;
  position: absolute;
  right: 0;
  top: 35%;
  height: 100vh;
}
.doctorLeftBg {
  width: 30%;
  position: absolute;
  left: 0;
  top: 90%;
}
/******* Footer ************/
.footerWrap {
  overflow: hidden !important;
  position: relative;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}
.footerHead1 {
  font-family: Poppins;
  font-size: 19px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
.compy {
  font-family: Poppins;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin: 15px 0px;
}
.footerPara1 {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
}
.footerParaPhone {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
}
.footerParaPhone i,
.footerParaList i {
  color: #ffffff;
  font-size: 14px;
  margin-right: 5px;
  line-height: 21px;
}
.footerParaList {
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  float: left;
  margin-right: 15px;
}
.divderHr {
  height: 1px;
  background: #ffffff;
}
.footerHead2 {
  font-family: Poppins;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
ul.footerLinks {
  margin: 0px;
  padding: 0px;
}
ul.footerLinks li {
  display: list-item;
  list-style-type: none;
  color: #ffffff;
  margin: 5px 0px;
}
ul.footerLinks li a {
  display: inline-block;
  text-decoration: none;
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
}
button.footerBtn {
  line-height: 40px;
  width: 100%;
  font-size: 22px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #c473c4;
  background: #ffffff;
  border: none;
  text-align: center;
  margin: 10px 0px;
}
button.footerBtn:hover {
  background: #a64fa6;
  color: #ffffff;
}

.footerSocialList i {
  color: #a54ea5;
  font-size: 1.2rem;
  margin-right: 5px;
  line-height: 21px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
}
.footerSocialList i:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: 0.3s all;
}
.footerSocialList {
  float: left;
  margin-right: 5px;
  text-align: center;
}
.clearRow {
  clear: both;
}
.xyzTest {
  position: absolute;
  width: 100%;
  background: #ccc;
}
/**************** Header ***************/
.webWeader {
  /* width: 85%; */
  width: 100%;
  position: fixed;
  /* left: 8%; */
  /* top: 16px; */
  top: 0px;
  z-index: 999;
}
#navbarToggler {
  /* height: 60px; */
  @media only screen and (min-width: 1199.99px) {
    /* height: auto !important; */
    height: 60px;
  }
}
.drHeader {
  width: 100%;
  background: #ffffff;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  /* border-radius: 20px; */
  padding: 15px !important;
  /* @media only screen and (max-width: 1199.99px) {
		/* height: auto !important; */
  /* background: #fff; */
}
*/ .navbar-nav {
  margin: 0px 10px !important;
  /* @media only screen and (max-width: 1199.99px) {
		background: #fff !important;
	} */
}
.nav-link {
  color: #1f274a !important;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin: 0px 20px !important;
}
.nav-link:hover {
  color: #d487d4 !important;
}
.navRight {
  position: absolute;
  right: 15px;
}
.navbar-toggler {
  color: #fff !important;
  border: none;
  outline: none;
  border-color: #a54ea5 !important;
}
.navbar-toggler i {
  color: #a54ea5 !important;
}
.navbar-light .navbar-toggler-icon {
  color: #a54ea5 !important;
}
/************** Home page ********/
.headerAlign {
  margin: 0% 10% 0% 10%;
}
.homeWrap {
  width: 100%;
  position: relative;
  padding-top: 12%;
  background: #fff;
}
.homeheading {
  font-size: 3rem;
  line-height: 4rem;
  font-family: Poppins;
  font-size: 56px;
}
.homeTopBanner {
  margin-top: -30px;
}
.homeTopBannerMobile {
  display: none;
}
.homePlayStore {
  position: relative;
  padding-left: 5%;
  margin-top: 30px;
}
.iosPlayStore {
  position: relative;
  padding-left: 5%;
  margin-top: 30px;
}
.nonHomePlayStore img,
.nonIosPlayStore img {
  margin: 20px 0px;
}
.baby-focus-main {
  width: 12% !important;
  padding-left: 5%;
}
.baby-focus-1 {
  width: 10% !important;
  padding-left: 5%;
}
.baby-focus-2 {
  width: 8% !important;
  padding-left: 5%;
}
.baby-focus-3 {
  width: 6% !important;
  padding-left: 5%;
}
.baby-focus-4 {
  width: 4% !important;
  padding-left: 5%;
}
.homePregaCurveBg {
  width: 100%;
  position: fixed;
  margin-top: -30px;
  padding: 3% 0% 3% 0%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(90deg, #d487d4 0%, #a54ea5 100%);
}
.homeCurveBgWrap {
  width: 100%;
  padding: 0% 8% 0% 8%;
  position: relative;
}
.homeCurveBg {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -60%;
}
.homePregaCurveBg,
.homeCorveData {
  width: 100%;
  position: absolute;
}
.homeCorveData {
  z-index: 10;
  width: 100%;
  position: relative;
}
.homepregBtm {
  padding: 3% 0% 0% 2%;
}
.homepregHeading {
  font-size: 1.5rem;
  color: #fff;
  font-family: Poppins;
  padding-left: 13%;
}
.homeWeekTitie {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  font-family: Poppins;
}
.btnClear {
  display: inline-block;
  vertical-align: top;
}
.homeCureHead {
  font-size: 2rem;
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
}
.homeCureHead2 {
  margin-top: 40px;
  font-size: 1.5rem;
  color: #fff;
  font-family: Poppins;
}
.homeCureHead2 span {
  font-size: 2rem;
  font-weight: 600;
}
.homeCardPrgaImg {
  width: 100%;
}
.homeCardPrgaPara h3 {
  color: white;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  margin-top: 15px;
}
.homeCardPrgaPara h3 span {
  color: white;
  font-size: 22px;
  line-height: 22px;
  font-weight: normal;
}
.homeCardPrgaPara span {
  color: white;
  font-size: 22px;
  line-height: 22px;
}
.homeSmilyWrap {
  background: #f9fbfe;
  padding-top: 700px;
  padding-bottom: 100px;
}
.smilyCont {
  width: 80%;
  margin: 0px auto;
  position: relative;
  background: #fff;
  padding: 30px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}
.smilyHeading {
  margin-bottom: 30px;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}
.smilyPara {
  margin-top: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
}
.feelingPointer {
  cursor: pointer;
}
.feelingPointer:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: 0.3s all;
}
.homeRightBg {
  width: 30%;
  position: absolute;
  right: 0;
  margin-top: -10%;
}
.weeklyHeWrap {
  width: 100%;
  position: relative;
  margin-top: 200px;
}
.weeklyHeWrap2 {
  width: 100%;
  position: relative;
}
.weeklyHeWrapBg {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
}
.pregaHeading {
  margin: 50px 0px 0px 0px;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}
.homeBabyHeading1 {
  color: white;
  text-align: right;
  padding-right: 32px;
  font-size: 64px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
}
.homeBabyHeading2 {
  color: #1f274a;
  text-align: left;
  padding-left: 32px;
  font-size: 64px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
}
ul.weeklyList {
  margin: 0;
  padding: 0;
}
ul.weeklyList li {
  display: list-item;
  list-style-type: none;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  background: url(/static/media/card_icon.ddbf7be7.svg) left top no-repeat;
  padding: 0px 20px 0px 40px;
  margin: 0px 20px;
  margin-bottom: 20px;
}
ul.weeklyList2 li {
  background: url(/static/media/card_icon2.c8695678.svg) left top no-repeat !important;
  color: #1f274a;
}

.curatedWrap {
  width: 100%;
  position: relative;
  padding: 10% 0% 0% 0%;
  /* padding-left: 135px; */
}

.containerLeftPara {
  position: absolute;
  top: 12%;
  right: 40%;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.containerLeftParaVideo {
  position: absolute;
  top: 12%;
  /* right:40%; */
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.containerRightPara {
  position: absolute;
  bottom: 10%;
  left: 40%;
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  color: #1f274a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}
.curatedImg {
  width: 60%;
  border-radius: 30px;
  padding: 3%;
}
.curatedTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-top: 10%;
  margin-bottom: 30px;
}
.homeCuratedPara {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  color: #1f274a;
}
.homeRightBg {
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 10%;
}
.homeVides {
  margin-top: 7%;
}
.homeVideoLeft {
  top: 12%;
  bottom: auto;
  /* right:25%; */
  left: 40%;
}
.homeVideoRight {
  bottom: 12%;
  top: auto;
  left: 0%;
  right: 40%;
}
.videoPlayBtn {
  position: absolute;
  bottom: 55%;
  left: 40%;
}
.videoPlayLeftBtn {
  position: absolute;
  bottom: 40%;
  left: 25%;
}
.videoPlayRightBtn {
  position: absolute;
  bottom: 40%;
  right: 28%;
}
/* .video-right{
  margin-top: -90px; 
  margin-right: 48px;
  @media (min-width: 768px) and (max-width: 1024px){ 
    margin-top: -72px;
    margin-right: 32px;
 }
} */
.yourHelthBg {
  width: 100%;
  position: relative;
  padding-bottom: 50px;
}
.yourHelthWrap {
  width: 100%;
  position: relative;
  padding-top: 5%;
}
.yourHelthImg {
  width: 100%;
  position: absolute;
}
.yourHelthImg2 {
  position: absolute;
  top: 70%;
  left: 0;
  width: 30%;
}
.yourHelthImg3 {
  width: 100%;
  position: absolute;
  padding-top: 120px;
}
.homeHelthHead {
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 16px;
}

.helthboxTiles {
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 60px 10px 20px 10px;
  margin-bottom: 50px;
}
.helthboxHeading {
  margin: 30px 0px 15px 0px;
  font-family: Poppins;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #1f274a;
}
.helthboxPara {
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  color: #857085;
}
.homeHelthRightBg {
  position: absolute;
  width: 30%;
  right: 0;
  top: -20%;
}
.atEasySpace {
  width: 100%;
  position: relative;
  padding: 5% 0px 0px 0px;
}
.atEasyBg {
  position: absolute;
  width: 100%;
  top: -30px;
}
.atEasylogoSpace {
  margin-bottom: 2%;
}
/********** added 21.10.22 ********/
.atEasyCustomTile {
  margin-top: -40%;
}
.atEasyboxTiles {
  /* width: 100%; */
  position: relative;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 40px 10px;
  /* margin-bottom: 50px; */
  cursor: pointer;
  margin-bottom: 10%;
}
.homeLinkBg {
  /* background: url('images/homeLinkbg.svg') center center no-repeat !important; */
  height: 300px;
  padding: 20px 0px;
  margin-bottom: 30px;
  @media (min-width: 768px) and (max-width: 1280px) {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    margin-bottom: 0px;
  }
}
.EasyIconPara {
  padding-top: 30px;
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
  color: #1f274a;
  font-weight: 600;
}
.homeLinkBg a {
  text-decoration: none;
}
button.modalLinkBtn:focus,
button.modalLinkBtn {
  border: none;
  box-shadow: none;
  outline: none;
}
#homeTestimonials {
  position: relative;
  width: 60%;
  margin: 0px auto;
  background: none;
  padding-bottom: 100px;
}
#homeTestimonials .carousel {
  background: none;
  box-shadow: none;
  border-radius: none;
}
#homeTestimonials .customslides {
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 28px;
  border: 1px solid #a64fa6;
  padding: 5%;
}

#homeTestimonials p {
  font-size: 18px;
  color: #a64fa6;
}
#homeTestimonials .carousel-control-next,
#homeTestimonials .carousel-control-prev {
  display: none !important;
}
.carousel-indicators li {
  background-color: #a54ea5;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  cursor: pointer;
  bottom: -50px;
  /* border: 1px solid #A54EA5 */
}

.homeModalBorder .ant-radio-button-wrapper {
  border: 1px solid #a3a9bf;
}
.homeModalBorder .ant-radio-button-wrapper:first-child {
  border-color: #a3a9bf;
}
.homeModalBorder .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.nonPregHomeSpace {
  padding-bottom: 250px;
}
.nonPregHomeBanner {
  position: absolute;
  right: 0;
  width: 100%;
  top: 3%;
}
/**** Listing page ***********/
.listSpacing {
  margin-top: 150px;
  margin-bottom: 80px;
}
.listHeading {
  position: relative;
  width: 100%;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
}
.main-img {
  width: 100%;
  border-radius: 10px;
}
.listDatePara {
  font-family: Poppins;
  font-size: 16px;
}
.listingDot {
  font-size: 4px;
  margin: 0px 15px;
  color: #a3a9bf;
}
.listIcons {
  font-size: 1.5rem;
  margin-left: 10px;
}
.listingImgPara {
  margin-top: 36px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #1f274a;
}
.sideCard {
  width: 100%;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  border-radius: 10px;
  margin-bottom: 30px;
}
.sideCard img {
  width: 100%;
}
.listSideWrap {
  width: 100%;
  padding: 20px 15px;
}
.ListSideHead {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #1f274a;
}
.listBottomLeftBg {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.listLeftBg {
  position: absolute;
  left: 0;
  bottom: -80%;
  width: 28%;
  z-index: 0;
}
.listRightBg {
  width: 20%;
  position: absolute;
  right: 0%;
  top: 25%;
}
/********* Doctor Listing **********/
.expertWrap {
  margin-top: 100px;
  position: relative;
  width: 100%;
  /* @media(min-width: 1200px){
    margin-top: 110px;
  } */
}
.expertHeading {
  position: relative;
  width: 100%;
  font-family: Poppins;
  font-size: 34px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  margin-bottom: 30px;
}
.expertAlign {
  text-align: right;
}
.consultSearch {
  border-radius: 25px !important;
  margin-top: 2%;
  width: 45% !important;
}
.repotSearch {
  border-radius: 25px !important;
  margin-top: 2%;
  width: 25% !important;
}
@media only screen and (max-width: 480px) {
  .repotSearch {
    width: 90% !important;
  }
}
@media only screen and (max-width: 600px) {
  .repotSearch {
    width: 90% !important;
  }
}
.drListingfilter {
  margin-left: 2%;
}
.listImgWrap {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
}
.listExpImg {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.listexpertBox {
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  margin-bottom: 30px;
}
.listBoxHeader {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #1f274a;
  margin: 0px 0px 10px 0px;
}
.listBoxPara {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a3a9bf;
  margin-bottom: 10px;
}
.drListLeftBg {
  width: 30%;
  position: absolute;
  bottom: -10;
  left: 0;
}
.drListLeftBg {
  position: absolute;
  bottom: 0%;
  left: 0;
}
.drListRightBg {
  position: absolute;
  top: 5%;
  right: 0;
}
.pageWrap {
  width: 100%;
  position: relative;
  padding: 150px 0px;
}
.pageWrap2 {
  width: 100%;
  position: relative;
  padding: 65px 0px;
}
.pageWrap3 {
  width: 100%;
  position: relative;
  /* padding: 110px 0px; */
  padding: 100px 0px;
}
.pageWrap4 {
  width: 100%;
  position: relative;
  padding: 120px 0px;
}
.dtDetailsHead {
  font-size: 34px;
  line-height: 72px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 600;
  text-align: left;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    /* margin-top: 24px; */
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .dtDetailsHead {
    font-size: 24px;
    line-height: 72px;
    font-family: Poppins;
    color: #1f274a;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 16px;
  }
}
.drDetailsImgRound {
  border-radius: 30px;
}

.drDetailsHeading2 {
  margin: 0;
  font-size: 48px;
  line-height: 60px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 600;
}

.drDetailsPara1 {
  position: relative;
  width: 100%;
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
  color: #1f274a;
  margin-bottom: 5px;
}

.drDetailsHeading3 {
  position: relative;
  width: 100%;
  font-size: 32px;
  line-height: 60px;
  font-weight: 600;
  font-family: Poppins;
  color: #1f274a;
}

.drlistImgRightWrapLeft {
  float: left;
  text-align: left;
  width: 10%;
  @media (max-width: 768.99px) {
    width: 15%;
    text-align: center;
  }
}

.drlistImgRightWrapRight {
  float: right;
  text-align: left;
  width: 90%;
  @media (max-width: 768.99px) {
    width: 85%;
  }
}
.ht-100 {
  height: 100%;
}
.drDetailsRightBoxWrap {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 20px 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }
}
.drDetailsRightBoxWrap2 {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 20px 30px;
  margin-bottom: 30px;
  @media (max-width: 768.99px) {
    padding: 15px;
  }
}

.drDetailsRightBox2 {
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
}

.drDetailsRightHead {
  margin: 0;
  font-family: Poppins;
  color: #1f274a;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
}

.drDetailsRightHead2 {
  margin-left: 0;
  font-family: Poppins;
  color: #a3a9bf;
  font-size: 12px;
  line-height: 18px;
  /* word-break: break-all; */
}

.drDetailsInfo {
  margin-left: 10px;
}

.m-left {
  margin-left: 10px;
}

.nonWeeklyHeWrap {
  width: 100%;
  padding-top: -20% !important;
  position: relative;
}
/* interest Page */
.ant-modal-content {
  border-radius: 20px !important;
}
/********** Scroll ************/
.dateSlotBorder::before {
  background: none !important;
}

.themeScroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #e9eff9;
}

.themeScroll::-webkit-scrollbar {
  width: 3px;
  background-color: #d4e4f8;
}

.themeScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}
/************************/
.drReadBox {
  border-radius: 30px;
  padding-bottom: 30px;
}

.drReadImgRound {
  border-radius: 30px;
  width: 100%;
  min-height: 350px;
  max-height: 350px;
}

.drReadHeading {
  margin: 15px 0px;
  font-family: Poppins;
  color: #1f274a;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #1f274a;
}

.drReadComment {
  font-size: 16px;
  font-family: Poppins;
  color: #1f274a;
}

.drArticleRound {
  border-radius: 10px;
  width: 100%;
  max-height: 700px;
}
/***Support Block***/
.BlockTitle {
  font-family: Poppins;
}

.ant-form-item-explain-error {
  padding-left: 50px;
}

.listSupportImg {
  border-radius: 20px !important;
  min-height: 330px;
  max-height: 330px;
  width: 100% !important;
}

.themBtn:focus,
.themBtn:active {
  background: none !important;
}

.invisiLink {
  text-decoration: none;
  font-size: 19px;
  font-family: Poppins;
}
/* appointment list page */
.apptListBox {
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  margin-bottom: 30px;
  position: relative;
}

.apptListHeader {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  color: #1f274a;
}

.profileEdit {
  position: absolute;
  bottom: 0;
  right: 35%;
}

.profileHeading2 {
  font-size: 1rem;
  line-height: 2rem;
  font-family: Poppins;
  font-weight: 600;
  margin: 50px 0px 25px 0px;
}

.profileRadioImg {
  padding: 10px;
}

.profileRadioLble {
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 5%;
}

.profileRadioLbleLarge {
  font-size: 1.2rem;
  line-height: 1rem;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 4%;
}

.profileRightBg {
  width: 20%;
  position: absolute;
  right: 0;
  top: 10%;
}
.profileRightBg1 {
  width: 20%;
  position: absolute;
  right: 0;
  top: 10%;
  height: 0vh;
}
.profileLeftBg {
  width: 40%;
  position: absolute;
  left: 0;
  bottom: -10%;
}
.profileLeftBg1 {
  width: 40%;
  position: absolute;
  left: 0;
  bottom: -10%;
  height: 0vh;
}
button.Supportbtn {
  height: 40px;
  line-height: 20px;
  padding: 6px 30px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: #ffffff;
  border: none;
  text-decoration: none;
  outline: none;
}

.supportTribeSelect {
  float: right;
}

.supportTribeTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 48px;
  line-height: 48px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 30px;
}

.drNotificationIcon svg {
  font-size: 20px;
  margin-top: -10px;
}

.drConsltBookinWrap {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 39px;
  padding: 30px;
  margin-bottom: 30px;
}

.ConstBookTitle {
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 30px;
  line-height: 30px;
  color: #1f274a;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

input.cbTextBorder {
  border-radius: 10px !important;
}

.cbTextBorder .ant-select-selector {
  border-radius: 10px !important;
}

.cbTextBorder .ant-picker-large {
  border-radius: 10px !important;
}

.bookTestBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
}

.bookTestBox p {
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  color: #1f274a;
  font-weight: 400;
  text-align: left;
}

.appointmentBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 5px 60px 5px !important;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
}

.boolTestHead {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1f274a;
  margin-bottom: 25px;
}

.boolTestHeade2 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1f274a;
  margin-bottom: 10px;
}

.bookReadMore {
  text-decoration: none;
  font-family: Poppins;
  font-weight: 600;
  color: #c473c4;
  font-size: 14px !important;
}

.toolBoxLabel {
  font-size: 16px;
  font-weight: 600;
}

.customDropDown {
  padding: 8px 10px !important;
  border: 1px solid #ccc;
  border-radius: 10px !important;
  font-size: 14px;
  box-shadow: none !important;
}

.customDropDown:hover,
.customDropDown:focus {
  border-color: #a64fa6 !important;
}

.calCalendar {
  width: 100% !important;
  border: none !important;
}

button.testColor {
  background-color: green;
}

button.testColor2 {
  background-color: red;
}

button.testColor3 {
  background-color: yellow;
}

.rdrNextPrevButton {
  background: none !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrMonth,
.rdrCalendarWrapper {
  width: 100% !important;
  font-size: 14px;
  line-height: 1.125em;
  color: #a0a096;
}

.rdrMonth,
.rdrCalendarWrapper {
  width: 100% !important;
  font-size: 16px;
  line-height: 1.125em;
  color: #a0a096;
}

.rdrMonths,
.rdrMonthAndYearPickers {
  pointer-events: none;
}

.rdrInRange,
.rdrStartEdge {
  color: #c473c4 !important;
}

.rdrEndEdge {
  color: #a54ea5 !important;
}

.reportTabWrap {
  padding: 0px 30px 15px 30px !important;
  border-radius: 15px;
}

.reportTabWrap .ant-tabs-tab {
  font-weight: 600;
  font-size: 16px;
  padding: 15px 30px;
}

.reportTabWrap .ant-tabs-tab-active {
  background: #c473c4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.reportTabWrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

.reportBox {
  width: 100%;
  padding: 30px;
  position: relative;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}

.reportDownload {
  font-size: 1.7rem;
  color: #a54ea5;
  cursor: pointer;
}

.reportTabWrap .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.reportTabWrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: static !important;
  overflow: auto !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: none !important;
}

.reportWrap {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.reportingView {
  border-radius: 15px;
  /* height: 100px;
  width: 50px; */
}

.errorInput {
  color: #ff4d4f;
}

.reportDelete {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #a54ea5;
  color: #ffffff;
  border: none;
  border-radius: 50%;
}

.reportDelete svg {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.reportFileName {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  overflow: hidden;
  padding: 5px 10px;
  background-color: #c473c4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

#pic {
  display: none;
}

.lablePic {
  width: 40%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  transition: all 0.5s;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  @media (max-width: 320px) {
    font-size: 14px;
  }
  @media (max-width: 280px) {
    font-size: 12px;
  }
}

.lablePic:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.myReportAddWrap {
  position: fixed;
  width: 20% !important;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.myReportAdd {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  color: white;
  font-size: 2rem;
  font-family: "Poppins";
  text-align: center;
  padding: 1rem;
  font-weight: 600;
  line-height: 22px;
  outline: none;
  border: none;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

ul.fellingList {
  margin: 5% 0% 2% 4%;
  padding: 0;
}

ul.fellingList li {
  font-family: "Poppins";
  color: #1f274a;
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 600;
  margin: 15px 0px;
}

.fellingLink {
  font-size: 0.98rem;
  line-height: 0.98rem;
  font-family: "Poppins";
  color: #a64fa6;
  font-weight: 600;
  text-decoration: underline !important;
}

.fellingLink:hover {
  color: #c473c4;
  text-decoration: none !important;
}

.file_upload {
  opacity: 0;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;
  /* older Safari browsers */
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.upload-img-ConsultBook {
  position: absolute;
  top: 45%;
  margin-left: -55%;
}

.consultReportBox {
  width: 100%;
  padding: 30px;
  position: relative;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 15px;
}
/* .consultReportBox:cl */

.nonSmilyMargin {
  margin-top: 20%;
}

.giftLink {
  cursor: pointer;
}

.giftBox {
  border-radius: 12px;
  box-shadow: 0px 8px 18px rgba(155, 162, 171, 0.3);
  /* background-color: #C473C4; */
  border-color: #c473c4;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.couponBox {
  border-radius: 10px;
  box-shadow: 0px 8px 18px rgba(155, 162, 171, 0.3);
  height: 430px;
  overflow-y: scroll;
}

/******Gift scroll*********/
.couponScroll::-webkit-scrollbar {
  width: 3px;
  height: 50px;
}

.couponScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.couponScroll::-webkit-scrollbar-thumb {
  background: #888;
}

.couponScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.expertDetail {
  margin: 0px;
}

.loginRightBg {
  /* margin: 150px; */
  height: 50%;
  width: 50%;
  margin-right: 45%;
}

.loginImg1 {
  width: 90%;
  margin-top: 91px;
  margin-right: 45px;
}

.button {
  margin-left: 130px;
}

@media only screen and (max-width: 768px) {
  .myReportAddWrap {
    width: 50% !important;
  }
}

.navbar-toggler {
  margin-inline: 20px;
}

@media (max-width: 281px) {
  .footerSocialList i {
    width: 25px;
    height: 25px;
    line-height: 16px;
    text-align: center;
    font-size: 0.9rem;
  }
}
#quickAccroding .ant-collapse-icon-position-left {
  margin-bottom: 15px !important;
}
#quickAccroding img {
  width: 100%;
  height: auto;
}
/**************** Mobile menu *******/
#overlayCloseRight {
  display: none;
}

/********** Hide input type number arrow 03.05.23 ************/

.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
/* Works for Firefox */
.inputNumber input[type="number"] {
  -moz-appearance: textfield !important;
}

/********************* Set container width as devise wise ***************************/
@media (min-width: 2200px) {
  .container {
    max-width: 1800px !important;
  }
}
@media (max-width: 1800px) {
  .container {
    max-width: 1600px !important;
  }
  .navRight {
    float: right !important;
  }
}
@media (max-width: 1600px) {
  .container {
    max-width: 1400px !important;
  }
}
@media (max-width: 1500px) {
  .container {
    max-width: 98% !important;
  }
}
@media (max-width: 1330px) {
  .navRight {
    float: right !important;
  }
  .webWeader .navbar-expand-xl {
    width: 100% !important;
    padding: 15px !important;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0px !important;
  }
}
@media (max-width: 1199px) {
  .navRight {
    float: none !important;
  }
}
@media (max-width: 768px) {
  .webWeader .navbar-expand-xl {
    padding: 15px 0px !important;
  }
}
.headerNavigation li,
.navbar-nav li {
  margin: 5px 0px 0px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px 15px 0px !important;
  }
}

.activePage {
  color: #a54ea5 !important;
}

.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 32px;
  z-index: 99;
  @media (max-width: 280px) {
    font-size: 16px;
    right: 20px;
  }
}

.ant-drawer-content-wrapper {
  z-index: 100;
}
.ant-spin-dot-item {
  background: #a54ea5 !important ;
}

.ant-spin-text {
  color: #a54ea5 !important ;
}

.img-animation {
  -webkit-animation: bounce 2s ease infinite;
          animation: bounce 2s ease infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

.resourceImg:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: 0.3s all;
}

.top-space {
  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

/* .curated-img{
  height: 230px;
} */
.blog-create-date {
  color: #ce80ce;
  background: #e4cfe4;
  border-radius: 16px;
  padding: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
}

.curated-img {
  box-shadow: 1px 6px 22px #ede8ed;
}
.curated-img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.3s all;
}

.loader-container {
  height: 500px;
}

.loader-container2 {
  height: 250px;
}

.view-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.descriptionImg img {
  max-width: 100%;
  height: auto;
}
.display-mobile {
  display: none;
  @media (max-width: 1199.99px) {
    display: block;
  }
}

.display-laptop {
  display: block;
  @media (max-width: 1199.99px) {
    display: none;
  }
}

.header-pic {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.remove-underline {
  text-decoration: none;
}

.no-slot-text {
  font-size: 24px;
  font-weight: 500;
  height: 90px;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 45px;
  }
}

@media (max-width: 1200px) {
  .signupHead {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .signupImg {
    width: 100%;
  }
  .submitRspn2 ul li a {
    padding: 10px 15px;
  }
  .formData {
    padding-right: 0px;
  }
  .carouselWWrap {
    width: 70% !important;
  }
  .carouselWWrapDr {
    width: 70% !important;
  }
  .nav-link {
    margin: 0px 15px !important;
  }
  .homeTopBanner {
    margin-top: 50px;
  }
  .iosPlayStore {
    margin-bottom: 60px;
  }
  .homeCurveBg {
    top: -35%;
    height: 210%;
  }
  .homeCurveBg {
    width: 100%;
    height: 204%;
  }
  .navRight {
    position: relative;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .message-area {
    height: 500px;
  }
  .signupHead {
    text-align: center;
  }
  .signupImg {
    width: 100%;
  }
  .signupHead {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .submitRspn2 {
    margin-bottom: 120px;
  }
  .submitRspn2 ul li {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .submitRspn2 ul li:last-child {
    margin-left: 0px;
  }
  .datePickerlbl {
    text-align: center;
  }
  .datePicker {
    width: 100%;
  }
  .radioInterest {
    width: 100%;
  }
  .doc-info {
    padding: 0px 0px 30px 0px;
  }
  .doctorHeadPrent {
    padding-top: 10%;
  }
  .doctorHeadSlide {
    margin-top: 6%;
  }
  .carouselWWrap {
    width: 87% !important;
  }
  .carouselWWrapDr {
    width: 87% !important;
  }
  .doc-img {
    width: 100%;
  }
  .doctorParaInline ul {
    padding-left: 2rem;
  }
  .doctorParaInline ul li {
    font-size: 18px;
  }
  .doctorParaInlinemobile ul {
    padding: 0;
  }
  .carousel-control-next {
    right: -15px !important;
  }
  .carousel-control-prev {
    left: -15px !important;
  }
  #drTestimonials {
    width: 100%;
  }
  .doctorHeadPrent {
    font-size: 24px;
  }
  .doctorHeadChild {
    font-size: 34px;
  }
  .webWeader {
    top: 0% !important;
  }
  .drHeader {
    background: none !important;
    box-shadow: none;
    padding-left: 0px !important;
  }
  #navbarToggler {
    background: #ffffff;
    padding: 15px 30px;
    border-top-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  /*********** Mobile Menu Tahsil ******************/
  #overlayCloseRight {
    position: fixed;
    width: 20%;
    height: 100%;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    font-size: 2rem;
    color: #ffffff;
    text-align: right;
    padding-right: 5%;
  }
  #navbarToggler {
    position: fixed;
    overflow-y: scroll;
    width: 80%;
    min-width: 80%;
    max-width: 80%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    border-radius: 0px !important;
    transition: all 0.01s ease !important;
    transition: none;
  }

  .show > #overlayCloseRight {
    display: block !important;
  }
  .collapsing {
    transition-delay: 1s;
    visibility: hidden;
  }
  .collapse.show {
    transition-delay: 1s;
    visibility: visible;
  }

  /*********** End Mobile Menu ****************/
  .drHeader {
    padding-right: 0px !important;
  }
  .nav-link {
    margin-left: 0px !important;
  }
  .homeCureHead {
    padding-top: 60px;
    text-align: center;
  }
  .homeCureHead2 {
    text-align: center;
  }
  .homeCuratedPara {
    text-align: center;
  }
  .homeTopBanner {
    display: none !important;
  }
  .homeTopBannerMobile {
    display: block;
    position: absolute;
    right: 0;
    top: -1%;
  }
  .homeheading {
    margin-top: 30px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-family: Poppins;
    font-size: 20px;
  }
  .homePlayStore {
    margin-top: 20px;
  }
  .iosPlayStore {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .homePlayStore img,
  .iosPlayStore img {
    width: 80%;
  }
  .iosPlayStore img {
    width: 70%;
  }
  .homeCureHead2 {
    margin-top: 15px;
  }
  .homeCurveBg {
    display: none !important;
  }
  .homeCurveBgWrap {
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    padding: 30px 0px;
  }
  .homeCardPrgaImg,
  .homeCardPrgaPara {
    text-align: center;
  }
  .homeCardPrgaPara {
    margin-bottom: 60px;
  }
  .homeSmilyWrap {
    padding-top: 60px;
  }
  .smilyHeading {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0px;
  }
  .smilyPara {
    font-size: 14px;
  }
  .smilyCont {
    width: 100%;
    padding: 30px 0px;
  }
  .weeklyHeWrap {
    margin-top: 100px;
  }
  .pregaHeading {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .baby-card {
    width: 80% !important;
    min-height: 100% !important;
    right: 20px !important;
    margin-top: 0px !important;
  }
  .mom-card {
    width: 80% !important;
    min-height: 100% !important;
    left: 20px !important;
    margin-top: -20px !important;
  }
  .curatedWrap {
    padding-top: 30px;
  }
  .containerLeftPara {
    font-size: 12px;
    line-height: 20px;
    top: -10px;
  }
  .containerRightPara {
    font-size: 12px;
    line-height: 20px;
    bottom: -10px;
  }
  .homeVides {
    margin-top: 80px;
  }
  button.consultBtnResp {
    padding: 7px 10px;
  }
  #homeTestimonials {
    width: 100%;
  }
  .hideMobile {
    display: none;
  }
  .homeSmilyWrap {
    background: #ffffff;
  }
  .atEasySpace {
    padding: 10px 0px 0px 0px;
  }
  .atEasyCustomTile {
    margin-top: 0px;
  }
  .pregaHeading {
    font-size: 20px;
  }
  .homeBabyHeading1,
  .homeBabyHeading2,
  .curatedTitle,
  .homeHelthHead {
    font-size: 30px;
    text-align: center;
  }
  .expertWrap {
    /* margin-top: 8px; */
    margin-top: 0px;
  }
  .themeBtnLink {
    margin-left: 65px;
  }
  .expertHeading {
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 0px;
  }
  .expertAlign {
    text-align: left;
  }
  .drListingfilter {
    float: right;
    margin-top: 15px;
  }
  .consultSearch {
    width: 80% !important;
    margin-left: auto;
    margin-bottom: 30px;
  }
  .listImgWrap {
    height: auto !important;
    margin-bottom: 30px;
  }
  .nonPregHomeBanner {
    top: 4%;
  }
  .nonPregHomeSpace {
    padding-bottom: 0px;
  }
  .nonHomePlayStore img {
    width: 45%;
    margin: 10px 0px;
  }
  .nonIosPlayStore img {
    width: 40%;
    margin: 10px 0px;
  }
  .pageWrap {
    padding: 70px 0px;
  }
  .pageWrap3 {
    padding: 60px 0px;
  }
  .pageWrap4{
    padding: 75px 0px;
  }
  .dtDetailsHead,
  .drDetailsHeading2 {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
  .drDetailsPara1 {
    text-align: center;
    margin-top: 15px;
  }
  .drDetailsHeading3 {
    text-align: center;
  }
  .dateSlotBorder {
    width: 100% !important;
    margin: 0;
  }
  .drReadBox {
    background: #fff;
    padding: 15px 15px;
    border-radius: 10px;
  }
  .drReadImgRound {
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    border-radius: 10px;
  }
  .drReadHeading {
    text-align: left;
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .drReadComment {
    margin-top: 3px;
    text-align: left;
    font-size: 14px;
    line-height: 14px;
  }
  .profileRadioLbleLarge {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-family: Poppins;
    font-weight: 600;
    margin-top: 1%;
  }
  .supportTribeSelect {
    float: none;
    text-align: center;
    margin-top: 50px;
  }
  .supportTribeTitle {
    text-align: center;
  }
  .cardSection1, .cardSection2, .cardSection3{
    height: auto !important;
  }
  
  .appmntImg img{
    min-height: 250px !important;
    max-height: 250px !important;
  } 
  .mobile-block{
    display: block !important;
  }
  .drFees{
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .listSideWrap {
    padding: 0;
    width: 80%;
  }
  .message-area {
    height: 480px !important;
  }
  .signupHead {
    text-align: center;
  }
  .signupImg {
    width: 100%;
  }
  .formData {
    padding: 0px 15px;
  }
  .submitRspn {
    text-align: center;
  }
  .submitRspn2 button {
    width: 100%;
  }
  .submitRspn2 button:last-child {
    margin-left: 0px;
  }
  .loginImg {
    width: 100%;
  }
  .mobileLoginBg {
    background-color: #f8fcff;
  }
  .submitRspn2 ul li {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .submitRspn2 ul li:last-child {
    margin-left: 0px;
  }
  .carouselWWrap {
    margin-top: 10%;
    width: 100% !important;
  }
  .carouselWWrapDr {
    margin-top: 10%;
    width: 100% !important;
  }
  .weeklyHeWrap2 {
    padding-top: 150%;
  }
  .reportTabWrap {
    padding: 15px 0px 0px 0px !important;
  }
  .loginMobileOverlap {
    margin-top: -80px !important;
    background-color: #ffffff;
    z-index: 1;
  }
  .giftValueHead {
    margin-top: 30px;
  }
}

@media (max-width: 281px) {
  #navbarToggler {
    padding-left: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .mt-tab-blog {
    margin-top: 80px !important;
  }
  /* .mt-tab-video {
    margin-top: 48px !important;
  } */
}

@media (min-width: 1024px) {
    .upcommingDiv{
        width: 60% !important;
    }
}

@media(max-width: 1200px){
  .drHeader {
    /* background: #fff !important; */
    box-shadow: none;
    padding-left: 0px !important;
  }
}

@media (max-width: 768px) {
    .containerWidth{
        max-width: 540px !important;
        width: 100% !important;
    }
}

@media(max-width: 576px){
  .mobile-height{
      height: 48px;
  } 
}

@media(min-width: 279px) and (max-width: 374px){
  .atEasySpace{
    margin-top: 85%;
  }
}

@media(min-width: 375px) and (max-width:424px){
  .atEasySpace{
    margin-top: 65%;
  }
}

@media(min-width: 425px) and (max-width:767px){
  .atEasySpace{
    margin-top: 70%;
  }
}
@media(min-width: 768px) and (max-width: 992px){
  .atEasySpace{
    margin-top: 10%;
  }
}
@media(min-width: 993px){
  .atEasySpace{
    margin-top: 5%;
  }
}

@media(min-width: 576px) and (max-width: 769px){
  .tab-margin{
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
}
body {
  font-family: Poppins !important;
  color: #1f274a;
}
.regitLogo {
  margin-top: 15px;
}
h1.regitWelcome {
  font-size: 34px;
  font-weight: 600;
}
p.regitPara1 {
  font-size: 20px;
  color: rgba(31, 39, 74, 0.7);
}
.Regitlabel {
  font-size: 15px;
  font-weight: 400;
}
.regitForgotLbl {
  font-size: 16px;
  font-weight: 600;
  color: #a54ea5;
}
.regitBtn {
  width: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
  background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
ul.regitButtonList {
  margin: 0px;
  padding: 0;
}
ul.regitButtonList li {
  display: block;
  list-style-type: none;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid rgba(31, 39, 74, 0.2);
  border-radius: 10px;
}
ul.regitButtonList li:last-child {
  margin-bottom: 0px;
}
ul.regitButtonList li a {
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #1f274a;
}
ul.regitButtonList li a svg {
  font-size: 30px;
  line-height: 16px;
  height: 40px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: -5px;
}

.socialIconMargin {
  margin-right: 15px;
}

.regitLableBottom {
  font-size: 16px;
  font-weight: 600;
  color: #1f274a !important;
}
.regitLableBottom a {
  color: #a54ea5 !important;
  text-decoration: none;
}
.regitRightImage2 {
  position: fixed;
  right: 0;
  margin-top: 10%;
  bottom: -90px;
}

@media (max-width: 576px) {
  .img-fix-login {
    width: 100%;
    display: none;
  }
  .contents-login {
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .regitRightImage2 {
    position: relative;
    right: auto;
    margin-top: auto;
    bottom: auto;
  }
  .regitLogo {
    display: none;
  }
  .regitMobileImg {
    background: #f8f8f8;
    border-radius: 0px 0px 30px 30px;
  }
  h1.regitWelcome {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .regitPara1 {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .Regitlabel {
    display: none;
  }
  .regitForgotLbl {
    font-size: 14px;
    line-height: 14px;
  }
  .regitBtn {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0px;
  }
  ul.regitButtonList li {
    box-shadow: 1px 6px 22px #ede8ed;
  }
  ul.regitButtonList li a {
    font-size: 16px;
  }
  .img-fix-login {
    width: 100%;
    display: none;
  }
}

@media (max-width: 992px) {
  .img-fix-login {
    width: 100%;
    display: none;
  }
}
.img-fix-login {
  width: 100%;
  height: 100%;
}

.ModalHeader .ant-modal-header {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.logpg-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: rgba(31, 39, 74, 0.7);
}

.lbsc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #1f274a;
}

.title-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #1f274a;
  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.logos-login {
  margin-top: 16px;
  margin-bottom: 20px;
}

.container-fluid {
  max-height: 100vh;
}

.atEasyHoverImg{
    width:100%;
    position: relative;
    background: #FFFFFF;
    padding: 10%;
    box-shadow: 1px 6px 22px #ede8ed;
    border-radius: 20px;
    cursor: pointer;
    height:100%;
    @media (min-width: 769px) and (max-width: 1024px){
      height: 150px;
      padding: 8%;
    }
    @media (min-width: 1024px) and (max-width: 1400px){
      height: 180px;
    }
  }
.atEasyHoverImg:hover{
  box-shadow: 4px 4px 2px #a54ea5;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.3s all;
}
.atEasyHoverImg svg path{
    fill: #1F274A;
    transition: all ease 0.3s;
  }
  .atEasyHoverImg:hover path{
    fill: #CF509B;
    transition: all ease 0.3s;
  }

@media (max-width:769px) {        
  .atEasyHoverImg img{
    max-height: 45px;
  }
}
@media (max-width:577px) {        
  .atEasyHoverImg img{
    max-height:100%;
  }
}
/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) { 
  .atEasyHoverImg{
    margin-top:15px;
  }       
  .atEasyHoverImg img{
    max-height: 70px;
  }
}
.atEasyHoverImg {
  width: 100%;
  position: relative;
  background: #ffffff;
  padding: 5%;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  cursor: pointer;
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 150px;
    padding: 8%;
  }
  @media (min-width: 1024px) and (max-width: 1400px) {
    height: 180px;
  }
}
.atEasyHoverImg:hover {
  box-shadow: 4px 4px 2px #a54ea5;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.3s all;
}
.atEasyHoverImg svg path {
  fill: #1f274a;
  transition: all ease 0.3s;
}
.atEasyHoverImg:hover path {
  fill: #cf509b;
  transition: all ease 0.3s;
}

@media (max-width: 769px) {
  .atEasyHoverImg img {
    max-height: 45px;
  }
}
@media (max-width: 577px) {
  .atEasyHoverImg img {
    max-height: 100%;
  }
}
/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .atEasyHoverImg {
    margin-top: 15px;
  }
  .atEasyHoverImg img {
    max-height: 70px;
  }
}

.Articlecontainer {
  padding-top: 0%;
  @media(min-width: 769px){
    padding-top: 4%;
  }
  @media (max-width: 576px) {
    padding-top: 30%;
  }
  @media (max-width: 485px) {
    padding-top: 40%;
  }
  @media (max-width: 425px) {
    padding-top: 50%;
  }
  @media (max-width: 375px) {
    padding-top: 60%;
  }
  @media (max-width: 375px) {
    padding-top: 70%;
  }
  @media (max-width: 300px) {
    padding-top: 90%;
  }
}

.happy-radio,
.lovers-radio {
  display: none;
}

/* the cards and toggle buttons are actually fat labels that can be clicked to set radio buttons */
label {
  display: block;
  cursor: pointer;
}
.selector {
  width: 100%;
  position: relative;
  min-height: 100%;
}

.baby-pick,
.mom-pick {
  grid-area: pick;
  height: 75%;
  margin: 50px auto 0;
  padding: 30px;
  border: 1px solid #ffffff;
  border-radius: 9999px;
  background-color: #b46d73;
  font-size: 1.25rem;
  -webkit-user-select: none;
  user-select: none;
}
.baby-pick::before,
.mom-pick::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
}

.baby-pick::before {
  margin-right: 10px;
  content: "\f0a8"; /* fa-arrow-circle-left */
}

.mom-pick::after {
  margin-left: 10px;
  content: "\f0a9"; /* fa-arrow-circle-right */
}

.app-header {
  display: flex;
  justify-content: center;
  height: 150px;
  align-items: center;
}
.app-header img {
  height: 50px;
}
.app-header > .app-name {
  font-size: 30px;
  margin: 0 20px;
}

.chat-system {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-box {
  width: 430px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.username-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
.username-label {
  padding: 0;
  margin: 10px 0;
}
.input {
  border-radius: 5px;
  border: 1px solid #eee;
  outline: none;
  width: 200px;
  height: 25px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  color: #777;
  background: #eee;
}

.message-area {
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: auto;
}

.message-control {
  display: flex;
  align-content: flex-end;
}

.message-control textarea {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-family: Poppins;
  box-sizing: border-box;
  padding: 5px;
  display: inline-block;
}

.message-control button {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  outline: none;
  border: none;
  font-family: Poppins;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
}

.new-message-count {
  font-size: 10px;
  display: inline-block;
  background: #a54ea5;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.message-area ul {
  padding: 0;
  margin: 0;
  height: 570px;
}

.message-area ul li {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px;
}
.message-area ul .user-pic {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 40%;
  overflow: hidden;
}

.message-area ul .user-pic img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.message-area ul .message-text {
  background: white;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  margin: 3px 5px;
  max-width: 270px;
  font-family: Poppins;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 14px;
}

.message-area ul .sentMessage-text {
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border-radius: 5px;
  color: white;
  font-size: 15px;
  padding: 7px 7px;
  box-sizing: border-box;
  margin: 5px 5px;
  max-width: 270px;
  font-family: Poppins;
  border-radius: 14px;
}

.file-input-container {
  position: relative;
}
.file-input-container input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.file-input-container label {
  display: block;
  color: #a54ea5;
  padding: 0 10px;
  cursor: pointer;
  background: #eee;
  height: 100%;
  display: flex;
  align-items: center;
}

.image-container {
  background: #eee;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 0 5px;
}

.attachement-display {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 10px;
  background: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.attachment-name {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px;
}

.remove-attachment {
  font-size: 20px;
  font-weight: 500;
  margin: 0 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.ant-drawer-body {
  overflow: hidden;
}

.option {
  border-radius: 20px !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: none !important;
}

/* slider css */

.App {
  width: 100%;
  height: 550px;
}

.slider img {
  width: 20rem;
}

.slider {
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0.4;
  margin: 0 auto;
}
.slider3{
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0;
  margin: 0 auto;
}

.slider-2 {
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0.4;
}

.activeSlide {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  opacity: 1;
}

.slider-page {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  opacity: 1;
}

.pageWeek img {
  border-radius: 50%;
  border: 8px solid #febcce;
}

.activeSlide img {
  border: 8px solid #fff;
  border-radius: 50%;
}

/* .arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  color: #fff;
}

.arrow svg {
  transition: color 300ms;
}

.next {
  right: 43%;
  top: -18%;
}

.prev {
  left: 43%;
  top: -18%;
} */

/* @media (max-width: 576px) {
}

@media(max-width: 576px){
  .next{
    right: 34%;
    top: -14%;
  }

  .prev{
    left: 33%;
    top: -14%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .next {
    right: 34%;
    top: -14%;
  }

  .prev {
    left: 33%;
    top: -14%;
  }
} */

@media (min-width: 576px) and (max-width: 768px) {
  /* .next {
    right: 28%;
    top: -20%;
  }

  .prev {
    left: 28%;
    top: -20%;
  } */
  .ftext {
    font-size: 24px !important;
  }
  #carouselWrap .carousel-item {
    margin-top: 0px;
  }
  #carouselWrap .carousel-control-next {
    position: absolute;
    right: 20% !important;
  }
}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .next {
    right: 42%;
    top: -14%;
  }
  .prev {
    left: 42%;
    top: -14%;
  }
} */

/* @media (max-width: 320px) {
  .prev {
    left: 30%;
    top: -14%;
  }
  .next {
    right: 30%;
    top: -14%;
  }
} */

/* when a radio button is set, swing the corresponding card fowards */
#baby-radio:checked ~ .selector .baby-card,
#mom-radio:checked ~ .selector .mom-card {
  -webkit-animation-name: swing-forwards;
          animation-name: swing-forwards;
}

@-webkit-keyframes swing-forwards {
  0% {
    z-index: 1; /* start behind other card */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: translateX(var(--swing));
            transform: translateX(var(--swing));
  }
  100% {
    z-index: 2; /* end in front of other card */
    -webkit-transform: translateX(var(--overlap)) scale(1);
            transform: translateX(var(--overlap)) scale(1);
  }
}

@keyframes swing-forwards {
  0% {
    z-index: 1; /* start behind other card */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: translateX(var(--swing));
            transform: translateX(var(--swing));
  }
  100% {
    z-index: 2; /* end in front of other card */
    -webkit-transform: translateX(var(--overlap)) scale(1);
            transform: translateX(var(--overlap)) scale(1);
  }
}

/* when a radio button is set, swing the opposite card backwards */
#baby-radio:checked ~ .selector .mom-card,
#mom-radio:checked ~ .selector .baby-card {
  -webkit-animation-name: swing-backwards;
          animation-name: swing-backwards;
}

@-webkit-keyframes swing-backwards {
  0% {
    z-index: 2; /* start in front of other card */
    -webkit-transform: translateX(var(--overlap));
            transform: translateX(var(--overlap));
  }
  50% {
    -webkit-transform: translateX(var(--swing));
            transform: translateX(var(--swing));
  }
  100% {
    z-index: 1; /* end behind other card */
  }
}

@keyframes swing-backwards {
  0% {
    z-index: 2; /* start in front of other card */
    -webkit-transform: translateX(var(--overlap));
            transform: translateX(var(--overlap));
  }
  50% {
    -webkit-transform: translateX(var(--swing));
            transform: translateX(var(--swing));
  }
  100% {
    z-index: 1; /* end behind other card */
  }
}
.homeBabyIcon img {
  width: 97px;
}
.weekday {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  /* identical to box height */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* patient_web */

  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
  border-radius: 10px 0px 0px 10px;
}
.weekinfo {
  width: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  padding: 30px;
}

/****************** Home changes 20.10.22 *********/
.babyAnimWrap {
  position: relative;
  width: 100%;
  width: 100%;
  padding: 3% 0%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -20px;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}
h3.headingAni {
  color: #ffffff;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  margin-top: 5px;
}
span.headingSpan1 {
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}
span.headingSpan2 {
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
/************************************/
.mobileHomeWrap {
  width: 100%;
  position: relative;
  display: none;
  margin-top: 35%;
}
.showMobile {
  display: none;
}
h1.curatedforyou {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  color: rgb(31, 39, 74);
  @media (max-width: 768px) {
    font-size: 24px;
  }
}
.homecard {
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 1px 12px #efe6ef;
  border-radius: 15px;
  padding: 15px;
}
h5.homeCardHeading {
  font-size: 16px;
  line-height: 22px;
  color: rgb(31, 39, 74);
}
.homeCardReadMore {
  position: absolute;
  right: 15px;
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
.ReadMore{
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
.ReadMore1{
  position: absolute;
  left: 15px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #a54ea5;
  text-align: right;
}
p.homePara2 {
  font-size: 16px;
  color: rgb(31, 39, 74);
}
@media (max-width: 768px) {
  .hideMobile {
    display: none;
  }
  .mobileHomeWrap {
    display: block;
    margin-top: 5%;
  }
}
.redm {
  color: #a54ea5;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}
.ftext {
  font-size: 32px;
  font-weight: 600;
  padding-right: 5px;
  padding-left: 5px;
  color: white;
}

@media only screen and (orientation: portrait) {
  .ftext {
    font-size: 20px;
    font-weight: 600;
    padding-right: 5px;
    padding-left: 5px;
    color: white;
  }
}
@media only screen and (orientation: portrait) {
  .texmore {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mobileHomeWrap {
    margin-top: 0%;
  }
}
.ims {
  border-radius: 15px;
  width: 100%;
  height: 100px;
}
.svapp {
  width: 55%;
}
.svapp:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s all;
}
.resourceImg {
  width: 40%;
}
@media (max-width: 576px) {
  .resourceImg {
    width: 50% !important;
  }
  #carouselWrap .carousel-control-next {
    right: 0% !important;
  }
}
/***** carousel CSS 22.11.2022 ********/
/*
#carouselWrap .carousel{
  background-color: none;
  box-shadow:none;
}
#carouselWrap .carousel-item{
  padding: 30px;
  z-index: 1;
}
.carouselDiv{
  width:100%;
  position: relative;
  border: 1px solid #A54EA5 !important;
  box-shadow: 1px 1px 12px #efe6ef;
  background-color:none !important;
  border-radius:30px;
  z-index: -1;
}
#carouselWrap h1{
  font-size: 36px;
}
#carouselWrap p{
  font-size: 22px;
}
.carousel-control-prev, .carousel-control-next{
  width: 50px;
  height:50px;
  position: absolute;
  left:-25%;
  padding: 3px;
  z-index: 999999;
  border: 5px solid #A54EA5;
}
*/
/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .mobileHomeWrap {
    margin-top: 10%;
  }
  .ftext {
    font-size: 22px !important;
  }
  .galaxyFoldFont span {
    font-size: 16px !important;
  }
  .next {
    right: 15%;
    top: -18%;
  }
  .prev {
    left: 15%;
    top: -18%;
  }
  .resourceImg {
    width: 70% !important;
  }
  .tribeModalIcon {
    width: 35px;
    margin-top: 8px;
  }
}
@media (max-width: 320px) {
  #galaxyFoldSmily div {
    width: 50%;
    margin-bottom: 30px;
  }
  #galaxyFoldSmily p {
    text-align: center;
  }
}

.headingH2 {
  /* text-align: center; */
  margin-bottom: 2rem;
  margin-top: 3rem;
  /* font-weight: 600; */
  /* font-size: 48px; */
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
}

.no-appointment {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  width: 60%;
  margin: 0rem auto;
  padding: 3rem;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  @media(max-width: 576px) {
    flex-direction: column;
    padding: 1.5rem;
  }
  @media(max-width: 768px){
    width: 100%
  }
  @media (min-width: 1800px) {
    width: 55%;
  }
}

.no-appointment-img {
  height: 10rem;
  width: 20rem;
}

.no-appointment-heading {
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    margin-top: 16px;
  }
}

a.appointmentsBtn-carousel {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 32px 10px 32px;
  text-decoration: none;
  background: linear-gradient(270deg, #ff7cc9 0%, #cf509b 100%) !important;
  border-radius: 30px;
  color: #ffffff;
  border: none;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.submitRspn{
  text-align: left;
  @media(max-width: 576px){
    text-align: center;
  }
}
.mom-header {
  display: flex;
  justify-content: space-between;
}

.baby-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  color: white;
}

.baby-left-arrow {
  font-size: 34px;
  /* margin-top: 20px; */
  margin-left: 32px;
  color: white;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 0px;
  }
}

.baby-right-arrow {
  font-size: 34px;
  /* margin-top: 20px; */
  margin-right: 32px;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 0px;
  }
}

/* custom-loader.css */
.custom-loader {
  display: flex;
  align-items: end;
  justify-content: start;
}

.dot-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #000; /* Change this color as needed */
  border-radius: 50%;
  margin: 0 4px;
  -webkit-animation: bounce 1.5s infinite;
          animation: bounce 1.5s infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

.week-font {
  font-size: 30px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.woman-animation, .man-animation, .mom-animation {
  -webkit-animation: bounce 2s ease infinite;
          animation: bounce 2s ease infinite;
}

.baby-size{
  display: inline-block;
  height: 48px;
  @media(max-width: 768px){
    height: 90px;
  }
}
h1.moodometerModalHeading{
    margin: 15px 15px 0px 15px;
    padding: 0px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: #A54EA5;
}
ul.fellingList{
    margin: 0px 15px 0px 30px;
    padding: 0px;
}

ul.fellingList li{
    display: list-item;
    font-size: 20px;
    line-height: 20px;
    color: #a64fa6;
}
ul.fellingList li span{
    font-size: 16px;
    line-height: 20px;
    color: #AE7FB7;
    font-weight: normal;
}
.fellingSpeek{
    font-size: 0.98rem;
    line-height: 0.98rem;
    font-family: 'Poppins';
    color: #A64FA6;
    font-weight:500;
    text-decoration: underline !important;
}
 p span {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    color: #1F274A;
}
#responsiveTable table{
    width: 100%;
    margin-bottom: 15px;
    border-collapse: collapse;
}
#responsiveTable table p{
    margin: 0px;
    color: #1F274A;
}
#responsiveTable table td{
    padding:5px 10px;
    border: 1px solid #CCC;
}
ul,ol{
    margin: 0;
    padding:0 0px 0px 15px;
}
ul li{
    margin: 0px 0px 15px 0px;
}
ul li span{
    font-size: 14px !important;
}
@media (min-width: 576px){
    /* .articlePadding{
      padding: 6%;
      padding-top: 0;
      padding-bottom: 0;
    } */
    .articleViewsWidth{
      width: 59% !important;
    }
}
p span {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #1f274a;
}
#responsiveTable table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
}
#responsiveTable table p {
  margin: 0px;
  color: #1f274a;
}
#responsiveTable table td {
  padding: 5px 10px;
  border: 1px solid #ccc;
}
ul,
ol {
  margin: 0;
  padding: 0 0px 0px 15px;
}
ul li {
  margin: 0px 0px 15px 0px;
}
ul li span {
  font-size: 14px !important;
}
@media (min-width: 576px) {
  /* .articlePadding{
      padding: 6%;
      padding-top: 0;
      padding-bottom: 0;
    } */
  .articleViewsWidth {
    width: 59% !important;
  }
}

body {
  font-family: Poppins !important;
  color: #1f274a;
}
.regitLogo {
  margin-top: 15px;
}
h1.regitWelcome {
  font-size: 34px;
  font-weight: 600;
}
h6.genderLabH6 {
  font-size: 16px;
  font-weight: 400;
  color: #1f274a;
}
.Regitlabel {
  font-size: 15px;
  font-weight: 400;
}
.regitForgotLbl {
  font-size: 16px;
  font-weight: 600;
  color: #a54ea5;
}
.regitBtn {
  width: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
  background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
ul.regitButtonList {
  margin: 0px;
  padding: 0;
}
ul.regitButtonList li {
  display: block;
  list-style-type: none;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid rgba(31, 39, 74, 0.2);
  border-radius: 10px;
}
ul.regitButtonList li:last-child {
  margin-bottom: 0px;
}
ul.regitButtonList li a {
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #1f274a;
}
ul.regitButtonList li a svg {
  font-size: 30px;
  line-height: 16px;
  height: 40px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: -5px;
}
.regitFacebook {
  border-radius: 15px;
  color: #4267b2;
}
.regitGoogle {
  color: rgb(219, 50, 54);
}
.regitFacebook svg {
  border-radius: 15px;
}
.regitLableBottom {
  font-size: 16px;
  font-weight: 600;
  color: #1f274a !important;
}
.regitLableBottom a {
  color: #a54ea5 !important;
  text-decoration: none;
}
.logos-dob{
  margin-top: 16px;
}
.img-dob-fix{
  width: 100%;
  height: 100vh;
}
/* .ant-radio-button-checked{
  background: #a54ea5;
} */
@media (max-width: 768px) {
  .regitLogo {
    display: none;
  }
  .regitMobileImg {
    background: #f8f8f8;
    border-radius: 0px 0px 30px 30px;
  }
  h1.regitWelcome {
    font-size: 24px;
    line-height: 32px;
    text-align: left !important;
  }
  .regitPara1 {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .Regitlabel {
    display: none;
  }
  .regitForgotLbl {
    font-size: 14px;
    line-height: 14px;
  }
  .regitBtn {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0px;
  }
  ul.regitButtonList li {
    box-shadow: 1px 6px 22px #ede8ed;
  }
  ul.regitButtonList li a {
    font-size: 16px;
  }
  .registerImg img {
    margin: 0px;
  }
  .registerImg {
    background-color: #f8fcff;
    height: 100%;
  }
  .img-dob-fix{
    width: 100%;
    display: none;
  }
}

.next-btn {
  @media (min-width: 577px) and (max-width: 1000px) {
    margin: 0px auto;
    width: 40%;
  }
  @media (min-width: 1001px) and (max-width: 1440px){
    margin: 0px auto;
    width: 50%;
  }
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width: 281px) {
  .genderInliine label {
    width: 100%;
    height: 80px !important;
  }
  .radioLb {
    font-size: 12px;
  }
}

.registerImg{
    position: absolute;
    right: 0;
    bottom: 0;
    top:0%;
    z-index: -1;
}
.registerImg img{
    height: 100%;
    margin-right:0px;
}
body{
    font-family: 'Poppins', sans-serif;
    color: #1F274A;
}
.regitLogo{
    margin-top:15px;
}
h1.regitWelcome{
    font-size: 34px;
    font-weight: 600;
}
p.regitPara1{
    font-size: 20px;
    color: rgba(31, 39, 74, 0.7);
}
.Regitlabel{
    font-size: 15px;
    font-weight: 400;
}
.regitForgotLbl{
    font-size: 16px;
    font-weight: 600;
    color: #a54ea5;
}
.regitBtn{
    width:100%;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding: 7px 0px;
    background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
    border-radius: 10px;
    color: #FFFFFF;
    border: none;
}
ul.regitButtonList{
    margin: 0px;
    padding: 0;
}
ul.regitButtonList li{
    display: block;
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid rgba(31, 39, 74, 0.2);
    border-radius: 10px;
}
ul.regitButtonList li:last-child{
    margin-bottom: 0px;
}
ul.regitButtonList li a{
    display: block;
    font-size: 16px;
    line-height: 40px;
    font-weight:400;
    color: #1F274A;
}
ul.regitButtonList li a svg{
    font-size: 30px;
    line-height: 16px;
    height:40px;
    font-weight:400;
    margin-right:5px;
    margin-top: -5px;
}
.regitFacebook{
    border-radius: 15px;
    color: #4267B2;
}
.regitGoogle{
    color: rgb(219, 50, 54);
}
.regitFacebook svg{
    border-radius: 15px;
}
.regitLableBottom{
    font-size: 16px;
    font-weight: 600;
    color: #1F274A !important;
}
.regitLableBottom a{
    color: #a54ea5 !important;
    text-decoration: none;
}
/************ Modal ***********/
.modalTitleH1{
    margin: 0px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: #1F274A;
}
.modalSubTitleH6{
    margin: 0px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #1F274A;
}
.modalOTP input{
    background: #FAFAFF;
    border: 1px solid #A3A9BF;
    border-radius: 5px;
}
.modalOTP input:focus{
    background: #FAFAFF;
    border:none;
    outline: 1px solid #A3A9BF;
    border-radius: 5px;
}
.modalResendBtn{
    color: #A64FA6;
    background: none;
}
.modalResendBtn:hover{
    color: #A64FA6;
    background: none;
}
.verifyButton{
    width: 60%;
    margin: 0px auto;
    background: linear-gradient(89.63deg, #DB7DDB 0.32%, #C473AC 0.33%, #A64FA6 99.68%);
    border-radius: 23px;
    border:none;
    text-align: center;
}
.verifyButton:hover{
    background: linear-gradient(89.63deg, #DB7DDB 0.32%, #C473AC 0.33%, #A64FA6 99.68%);
}
.react-confirm-alert-button-group button{
    background:linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important;
    font-weight: 400;
}
.react-confirm-alert-body h1{
    font-size: 1.8rem;
}

@media (max-width: 768px) {
    .registerImg{
        position: relative;
        right: auto;
        bottom: auto;
        top:auto;
    }
    .regitLogo{
        display: none;
    }
	.regitMobileImg{
        background: #F8F8F8;
        border-radius: 0px 0px 30px 30px;
    }
    h1.regitWelcome{
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .regitPara1{
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }
    .Regitlabel{
        display: none;
    }
    .regitForgotLbl{
        font-size: 14px;
        line-height: 14px;
    }
    .regitBtn{
        font-size: 16px;
        line-height: 16px;
        padding:15px 0px;
    }
    ul.regitButtonList li{
        box-shadow: 1px 6px 22px #EDE8ED;
    }
    ul.regitButtonList li a{
        font-size: 16px;
    }
    .img-fix-signup {
        width: 100%;
        display: none;
    }
}
.img-fix-signup {
    width: 100%;
    height:100%;
}
.img-fix-logo {
    width: 200px;
    height: 100%;
}
.contents-login {
    margin-left: 107px;
}

@media (max-width: 576px) {
    .img-fix-signup {
        width: 100%;
        display: none;
    }
    .contents-login {
        margin: 20px;
    }
}

@media (max-width:992px) {
    .contents-login {
        margin: 20px;
    }
    .img-fix-signup {
        width: 100%;
        display: none;
    }
    /* .img-fixs {
        width: 100%;
        height: 100%;
        display: block;
    } */
    .img-fix-logo {
        width: 100%;
        height: 100%;
    }
}
/* .img-fixs{
    display:none;
} */

.logpg-signup {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: rgba(31, 39, 74, 0.7);
}

.lbsc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    color: #1F274A;
}

.title-signup {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: #1F274A;
    @media(max-width: 576px) {
        font-size: 24px;
    }
}

.logos-signup {
    margin-top: 16px;
    margin-bottom: 20px
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    ul.regitButtonList li a svg{
        padding: 0px 15px;
    }
    .modalOTP input{
        width: 35px;
        height: 35px;
    }
    .react-confirm-alert-body{
        width: 95%;
        margin: 0px auto;
    }
    .react-confirm-alert-body h1{
        font-size: 1.1rem;
        font-weight: 600;
    }
}

.container-fluid{
    max-height: 100vh;
}
body {
  font-family: Poppins !important;
  color: #1f274a;
}
.regitLogo {
  margin-top: 15px;
}
h1.regitWelcome {
  font-size: 34px;
  font-weight: 600;
}
p.regitPara1 {
  font-size: 20px;
  color: rgba(31, 39, 74, 0.7);
}
.Regitlabel {
  font-size: 15px;
  font-weight: 400;
}
.regitForgotLbl {
  font-size: 16px;
  font-weight: 600;
  color: #a54ea5;
}
.regitBtn {
  width: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
  background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
ul.regitButtonList {
  margin: 0px;
  padding: 0;
}
ul.regitButtonList li {
  display: block;
  list-style-type: none;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid rgba(31, 39, 74, 0.2);
  border-radius: 10px;
}
ul.regitButtonList li:last-child {
  margin-bottom: 0px;
}
ul.regitButtonList li a {
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #1f274a;
}
ul.regitButtonList li a svg {
  font-size: 30px;
  line-height: 16px;
  height: 40px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: -5px;
}
.regitFacebook {
  border-radius: 15px;
  color: #4267b2;
}
.regitGoogle {
  color: rgb(219, 50, 54);
}
.regitFacebook svg {
  border-radius: 15px;
}
.regitLableBottom {
  font-size: 16px;
  font-weight: 600;
  color: #1f274a !important;
}
.regitLableBottom a {
  color: #a54ea5 !important;
  text-decoration: none;
}
.ant-radio-group-solid label {
  background-color: #fff;
  border: 1px solid #ccc !important;
  box-shadow: 1px 6px 22px #ede8ed !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  border: 1px solid #a54ea5 !important;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.logos-interest {
  margin-top: 16px;
}
.img-interest-fix {
  width: 100%;
  height: 100vh;
}
@media (max-width: 768px) {
  .regitLogo {
    display: none;
  }
  .regitMobileImg {
    background: #f8f8f8;
    border-radius: 0px 0px 30px 30px;
  }
  h1.regitWelcome {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .regitPara1 {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .Regitlabel {
    display: none;
  }
  .regitForgotLbl {
    font-size: 14px;
    line-height: 14px;
  }
  .regitBtn {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0px;
  }
  ul.regitButtonList li {
    box-shadow: 1px 6px 22px #ede8ed;
  }
  ul.regitButtonList li a {
    font-size: 16px;
  }
  .registerImg img {
    margin: 0px;
  }
  .registerImg {
    background-color: #f8fcff;
    height: 100%;
  }
  .img-interest-fix{
    width: 100%;
    display: none;
  }
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #201818;
  background: #ffffff;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #201818;
  background: #ffffff;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #666167;
  background: #ffffff;
}
.ant-radio-button .ant-radio-button-checked {
  color: #201818;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: 0 0 0 0px #cfc2cf;
}

.your-stage-option-1 {
  margin-top: 3%;
  color: white;
}

.your-stage-option-2 {
  margin-top: 3.5%;
  color: black;
}


.emojicontainer{
    display: flex; 
    height: 30%;
    padding: 2%;
  }
  
  .emoji1{ 
    width: auto;
    height: auto;
    text-align: center; 
  }

  .card1p-container{
    margin-top: 10%;
    position:absolute; 
    width:60%;
    margin-left:20%;
    display: flex;
    z-index: 1;
    justify-content: space-around;
  }
  
  .card2p-container{
      margin-top: 40%;
      position:absolute; 
      width:60%;
      margin-left:20%;
      z-index: 1;
      display: flex; 
      justify-content: space-evenly;
  }
  
  .card3p-container{
      margin-top:110%;
      position:absolute; 
      width:60%;
      margin-left:20%;
      z-index: 1; 
      display: flex;
      justify-content: space-evenly; 
  }
  
  .shortp-up{
      width: 160px;
      height: 80%;
      padding:60px;  
      align-items: center;
      justify-content: center;
      z-index: 1;
      border-radius: 20px;
      margin-top: -50px;
      box-shadow: 1px 6px 22px #EDE8ED; 
  }
  
  .emojicontainer{
    display: flex;
    justify-content: space-around;
    height: 30%;
    padding: 2%;
  }
  
  .emoji1{ 
    width: auto;
    height: auto;
    text-align: center;
  }
  
  .info1{ 
    width: auto;
    height: auto;
  }
  
  .shortp{ 
      width: 160px;
      height: 80%;
      padding:60px;  
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-shadow: 1px 6px 22px #EDE8ED;    
  }
   .card-img-top{
       margin-top: 2px;
      height: 60px;
      width : 100px; 
  }
   
  
  .shortp-exp{ 
    width: 190px;
    height: 250px;
    padding:60px;  margin-left: 15px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 1px 6px 22px #EDE8ED;    
  }
  
  .card-img-top-exp{
     margin-top: 2px;
    height: 60px;
    width : 100px; 
  }

  @media (min-width: 800px) and (max-width: 1024px){
  
    .shortp{ 
      width: 140px;
      height: 60%;
      padding:50px; 
    }
  
    .shortp-up{ 
      width: 140px;
      height: 60%;
      padding:50px; 
    }
  
    .card-img-top{
      margin-top: 2px;
     height: 60%;
     width : 60px;
     margin-left: 0px; 
    } 
  
    .shortp-exp{ 
      width: 160px;
      height: 60%;
      padding:50px; 
    }
  
    .card-img-top-exp{
      margin-top: 2px;
     height: 60%;
     width : 60px;
     margin-left: 0px; 
    }
  
    .info1{
      margin-top: 13%;
    } 
  } 
  @media (min-width: 550px) and  (max-width: 800px){
       
    .shortp{ 
      width: 100px;
      height: 50%;
      padding:40px; 
    }
  
    .shortp-up{ 
      width: 100px;
      height: 50%;
      padding:40px; 
    }
  
    .card-img-top{
      margin-top: 3px;
     height: 50%;
     width : 90px;  
    }
  
    .shortp-exp{ 
      width: 120px;
      height: 50%;
      padding:40px; 
    }
  
    .card-img-top-exp{
      margin-top: 3px;
     height: 60%;
     width : 100px;  
    }
  
    .info1{
      margin-top: -13%;
    }
  } 
  @media (min-width: 300px) and  (max-width: 580px){
    .shortp{ 
      width: 60px;
      height: 30%;
      padding:30px; 
      flex-direction: column;
    }
  
    .shortp-up{ 
      width: 80px;
      height: 40%;
      padding:30px; 
      flex-direction: column; 
    }
  
    .card-img-top{
      margin-top: 25px;
      height: 80%;
      width : 90px;  
    }
  
    .shortp-exp{ 
      width: 80px;
      height: 30%;
      padding:40px; 
    }
  
    .card-img-top-exp{
      margin-top: 5px;
      /* height: 170%; */
      width : 90px; 
    }  
  
    .emoji1{
      width: 20px;
      height: 50px;
      text-align: center;
    }
  
    /* .ant-btn-round.ant-btn-lg{
      height: 20px;
    } */
  
    .info1{
      margin-top: -53%;
    }  
    .carousel-caption {
      top: 100;
      bottom: -25px;
    }
  
    .carousel-control-next {
      height: 170px;
      width: 6%; 
      margin-right: 2%;
    }
  
    .carousel-control-prev {
      height: 170px;
      width: 6%; 
      margin-left: 2%;
    }
   
  }
  
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
@media only screen and (orientation: portrait) {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
    width: 100%;
  }
}

.logpic {
  margin-bottom: 30px;
  border-radius: 50%;
}
.cliimg {
  cursor: pointer;
  margin-top: 100px;
  margin-left: -48px;
}

#interest label,
#tribe label,
#menstrualDetail label {
  margin-right: 15px;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  #interest label,
  #tribe label,
  #menstrualDetail label {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.profile-pic {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: 20% 10%;
}

.row-Doc {
  /* margin-top: 10%;  */
  text-align: center;
}

.carousel {
  box-shadow: 1px 1px 12px #efe6ef;
  background-color: #fff;
  border-radius: 28px;
}

.doc-text {
  font-size: 15px;
}

/* .Spec-card{
    margin-top:126px;
} */

.cards {
  width: 100% !important;
  margin: 0px !important;
  height: auto !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.empmsg {
  border-radius: 15px;
  color: #afafaf;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.tabsbg .ant-tabs-tab-active {
  background: #c473c4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.sc {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.svf {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 21px;
  background: lightgray;
}

@media (max-width: 768px) {
  .cards {
    width: 100% !important;
    margin: 0px auto !important;
    margin-bottom: 30px !important;
  }
  .cards img {
    margin-bottom: 30px;
  }
  .cards h5 {
    font-size: 1rem;
  }
  .carcons {
    display: inline-flex;
  }
  .cdzbPz {
    font-family: Poppins;
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    color: white;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }
  .iasVts .customslides {
    position: relative;
    background: #b35fb3;
    box-shadow: 1px 1px 12px #efe6ef;
    border-radius: 28px;
    border: 1px solid #a64fa6;
    padding: 5%;
  }
  .iasVts p {
    font-size: 18px;
    color: #fefefe;
  }
}
@media (max-width: 281px) {
  .cards img {
    margin-bottom: 10px;
  }
  .cards h5 {
    font-size: 0.7rem;
  }
}
/******** Upcoming appointment Heading *******/
h1.headingH1 {
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 3%;
  margin-top: 5%;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  text-align: center;
  @media (max-width: 550px) {
    margin-top: 10%;
    font-size: 38px;
    line-height: 40px;
  }
}
h1.headingH1 span {
  font-size: 48px;
  @media (max-width: 550px) {
    font-size: 20px;
  }
}
.customCarouselArrow a.carousel-control-next {
  visibility: visible;
  right: -15% !important;
  @media (max-width: 550px) {
    right: -5% !important;
  }
}

.customCarouselArrow a.carousel-control-prev {
  visibility: visible;
  left: -15% !important;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  @media (max-width: 550px) {
    left: -5% !important;
  }
}

a.appointmentsBtn-carousel {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 16px;
  text-decoration: none;
  background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
  border-radius: 30px;
  color: #ffffff;
  border: none;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.submitRspn {
  text-align: left;
  @media (max-width: 576px) {
    text-align: center;
  }
}

.mt-container {
  /* margin-top: 9%; */
  margin-top: 8%;
  @media screen and (max-width: 768px) {
    margin-top: 4%;
  }
}

.upcommingDiv {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  /* width: "75%"; */
  margin: 0rem auto 2rem auto;
  padding: 3rem;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.no-upcoming-text {
  display: "flex";
  flex-direction: column;
  @media (max-width: 576px) {
    margin-top: 24px;
  }
}

.upcomingIcon {
  height: 10rem;
  width: 20rem;
}

#detail{
    padding-left: 8%;
    margin-top: -50%;
}

.docDetail-img{  
    border-radius: 25px;
    width: 115%;
}

.doc-detail-info{
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
    margin-left: 5%; 
}

.heading{
    font-size: 48px;
}






.jQOMdP {
    width: 265px;
    height: 243px;
}
#blob {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 50px;
    padding-left: 10%;
}

.blob-img{ 
    margin-right: 3%;
    margin-bottom: 2%; 
}

#blob-med{
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 130px; 
}

#big-blob{
    background: #FFFFFF;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 39px;
    width: 80%;
    height: 350px; 
}

.videoinb{
    margin-right: 40%;
    height: 6%;
}

.in-blobs{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-top: 5%;
    padding-left: 10%;
}

.blob-info{
    padding-left: 20%;
    font-size: 12px;
}


 

@media (min-width: 300px) and  (max-width: 580px){
    .docDetail-img{
        margin-top: 15%;
        width: 130px;
        padding-left: 10%;
    }

    #detail{
        margin-top: 10%;
    }

    .doc-detail-info{
        font-size: 15px;  
        height: 80%;
        margin-top: 10%; 
        padding-left: 5%;
        justify-content: left;
        padding-bottom: 5%;
    }

    .doc-des{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    #blobs{
        margin-left: 12%;
        padding-right: 0%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .heading{
        font-size: 20px;
    }

    .heading1{
        display: none;
    }

     
}

@media (min-width: 550px) and  (max-width: 800px) {
    .heading1{
        margin-top: 18%;
    }
    
    .docDetail-img{
        margin-top: 8%;
        width: 130px;
        padding-left: 10%;
    }

    #detail{
        margin-top: -95%;
    }

    .heading{
        font-size: 30px;
    }

    #blob{
        width: 100%;
    }

    #big-blob{
        width: 100%;
        height: 320px;
    }

    #blob-med{
        width: 100%;
        height: 150px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {

}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    .dtDetailsHead, .drDetailsHeading2{
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
}
@media (max-width: 768px) {
    .time-card-head {
        font-size:10px;
        padding: 10px;
    }
}

#title-bar{ 
    margin-top: 10%;  
}

.consult-search{
    width: 40%;
    border-radius: 27px; 
    margin-top: 2%;
}

.filter{
    margin-left: 1%; 
}

.card-img{
    margin-top: 5%;
    border-radius: 10px !important;
}

.today-img{ 
    width: 100%;
    padding: 5%;
    margin-right: 10%;
    border-radius: 30px !important;
     
}

.ant-card-body {
    padding: 8%;
    padding-left: 1%;
}

.consult-info{
    padding-left: 5%;
}

.doc-card{
    box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
    border-radius: 8px;
}

#next-row{
    margin-top: 5%; 
}
/* .ant-checkbox-wrapper {
    margin-left: 8px;
} */
.language-checkbox.ant-checkbox-wrapper{
width: 150px;
margin: 0px 15px 0px -20px;
}
.concern-checkbox.ant-checkbox-wrapper{
width: 200px;
margin: 5px;
}
.expertFilter{
    margin-left: 15px;
    cursor: pointer;
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:768px) {        
    .expertFilter{
        margin-left: 5px;
        margin-top: 10px;
    }
    .expertHeading{
        font-size: 24px;
        text-align: left;
    }
}
@media (max-width:281px) {        
    .expertFilter{
        margin-left: 0px;
        margin-top: 10px;
    }
}
.supportTribeTextAlign{
    text-align: right;
}
@media (max-width: 768px) {
    .supportTribeTextAlign{
        text-align: center;
    }
}
.SuccessModal{
    
}
.ant-form-item-explain-error {
    padding-left: 0px;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {

margin-left:49%
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-conten {
    margin-right: 0px
}

fils {
    width: auto;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #EDE8ED;
    border: none;
}
    fils:last-child

{
    width: auto;
    height:-webkit-max-content;
    height:max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #EDE8ED;
    border: none;
}

fils:first-child {
        width: auto;
        height: -webkit-max-content;
        height: max-content;
        border-radius: 40px;
        box-shadow: 1px 6px 22px #EDE8ED;
        border: none;
    }
.ant-checkbox-wrapper-checked {
    border: 1px solid blue;
}
.linkstag {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    /* identical to box height */
    text-decoration:none;
    padding:0px 15px;
    color: #a54ea5;
    text-align:right;
    @media (min-width: 769px) and (max-width: 1024px){
        font-size: 14px;
    }
}

.linkstag:hover {
    color: black
}
.acts {
        color: black
    }

.attach-report-btn{
    margin-top: 24px;
    @media(max-width:576px){
        margin-top: 12px;
    }
}

.appointment-id{
    @media (min-width: 321px) and (max-width: 375px){
        font-size: 13px !important;
        margin-left: 8px !important ;
    } 
    @media (min-width: 280px) and (max-width: 320px){
        font-size: 12px !important;
        margin-left: 8px !important;
    }
}
@media (max-width: 768px) {
    .linkstag {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size:13px;
        /* line-height: 8px; */
        text-decoration: none;
        color: #a54ea5;
    }
    .linkstag:first-child{
        padding-left: 0px !important;
    }
    h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 10px;
        line-height: 1.5;
    }
    .rinfo {
        font-size: 16px;
        font-weight: 500;
    }
    .helthboxHeading{
        font-size: 1rem;
        margin: 15px 0px;
    }   
}
.rinfo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    /* identical to box height */
    color: #1F274A;
    @media(max-width: 332px){
        font-size: 19px;
    }
}
h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
}
.uploadModalHeader .ant-modal-header{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    margin-left: 0px;
}
.ant-modal-confirm-btns button{
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
}
/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    span.hideGalaxyFold{
        display: none;
    }
    .ant-modal-confirm .ant-modal-body{
        padding: 15px !important;
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
        margin-left:0px;
    }
    .ant-modal-confirm-content p span{
        width: 100% !important;
        position: relative;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding: 0px 5px;
    }
    .galaxyFoldUpload {
        width: 100%;
        position: relative;
    }
    .helthboxHeading{
        font-size: 1rem;
        margin: 15px 0px;
    }
  }

#paymentForm
{
    width: 100%;
    height: auto;
}

@media screen and (min-width: 576px)
{

}
.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-btn {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid transparent;
  transition: border-color 0.3s, color 0.3s;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 4px 0px 12px 0px;
  border: 1px solid lightgray;
}

.category-btn:last-child{
    margin-bottom: 0;
}

.category-btn:hover {
  border-color: #cf509b;
  color: #cf509b;
}

.ant-modal-header{
border-radius: 20px 20px 0px;
}

.selected{
  border: 1px solid transparent;
  transition: border-color 0.3s, color 0.3s;
  border-color: #a54ea5;
  color: #a54ea5;
}
/* .roe1 {
    margin-top: 9%;
    padding-left: 25px;
    margin-bottom: 5px;
    @media (min-width: 300px) and (max-width: 580px) {
      margin-top: 8%;
    }
    @media (min-width: 581px) and (max-width: 768px) {
      margin-top: 3%;
    }
  } */

.tabbable-panel {
    width: 100%;
    position: relative;
}  
.tabbable-line > .nav-tabs {
border: none;
background-color: #fff;
border-radius: 10px 10px 0px 0px;
margin-bottom: 30px;
border-bottom:1px solid rgba(31, 39, 74, 0.2);;
}
.tabbable-line > .nav-tabs > li > a {
border: 0;
color: #1F274AB2;
display: block;
font-size: 15px;
font-weight: 500;
padding: 15px 30px;
text-decoration: none;
text-wrap: nowrap;
}
.tabbable-line > .nav-tabs > li > a > i {
color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li a:hover {
border-bottom: 4px solid #a54ea5;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
border: 0;
background: none !important;
color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
margin-top: 0px;
}
.tabbable-line > .nav-tabs > li a.active {
border-bottom: 4px solid #a54ea5;
position: relative;
color:#a54ea5;
}
.tabbable-line > .nav-tabs > li a.active > a {
border: 0;
color: #a54ea5;
}
.tabbable-line > .nav-tabs > li a.active > a > i {
color:#a54ea5;
}
.tabbable-line > .tab-content {
    border-radius: 0px 0px 10px 10px;
}
.portlet .tabbable-line > .tab-content {
padding-bottom: 0;
}
.addTabBg1{
    background-color: #FFFFFF;
    padding: 15px;
}
.addReportRightWrap {
    position: relative;
    right: 0;
    top: -10px;
}
.addReportBtn{
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    margin-right: 15px;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 42px;
    color: #fff;
}
.addReportBtn:hover{
    color: #FFF;
}
hr.borderDivider{
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin: 15px;
}
span.fileNameHeader{
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
}
a.fileNamedownload{
    text-decoration: none;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    margin-left:15px;
    font-style: normal;
    color: #a54ea5;
}

a.fileNamedownload svg{
    margin-left:10px;
}


@media (max-width: 768px) {
	span.fileNameHeader{
        line-height:16px;
        word-wrap: break-word;
    }
    .addReportRightWrap {
     right:0;
    }
}
/******* Test Report *******/
.testReportCard{
    width: 100%;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
}
.testReportCardInner{
    width: 100%;
    position: relative;
    overflow: auto;
    margin-bottom: 15px;
}
.testReportLeft{
    width:15%;
    float: left;
    clear:both;
    padding-top:5px;
}
.testReportRight{
    width: 85%;
    float: left;
}
.testReportCardHead1{
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color:rgba(31, 39, 74, 0.7);
}
.testReportCardHead2{
    font-size: 15px;
    line-height:22px;
    font-weight: 500;
    color:#1F274A;
}
.testReportNormal{
    font-size: 14px;
    line-height: 36px;
    color: #22A63F;
    font-weight: 600;
}
.reportSearch {

}
.reportSearchInput{
    background: #F8F9FA;
    border: 1px solid #EFEFEF;
    border-radius: 30px !important;
    width:200px;
}
.reportSearchInput input{
    background: none;
}
h3.noReportHeading{
    font-size: 33px;
    font-weight: 500;
    margin-top: 3%;
}
.tas {
    width: 100%;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px;
    margin: 2px;
    background: white;
    box-shadow: 1px 6px 22px #EDE8ED;
}
.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}
.selectedFilter{
    border: 1px solid transparent;
    transition: border-color 0.3s, color 0.3s;
    border-color: #a54ea5;
    color: #a54ea5;
}
.resetButton{
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    color: #fff;
}
.resetButton:hover ,.resetButton:active, .resetButton:focus{
    color: #FFF;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
}
.gifttext {
    padding: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1F274A;
}
.logogift {


    top: 250px;
}
.subgift {

    width: 598px;
    height: 60px;
    padding: 30px;
    top: 500px;
}
.subgiftright {
    left: 133px;
    top: 21px;
}
.amount {
    
    width: 80%;
    border-radius:10px;
}
.btngift {
    width: 80%;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
    box-shadow: 1px 6px 22px #EDE8ED;
    border: solid 1px #a54ea5;
    border-radius: 42px;
    padding:8px;
    color:#fff;
    margin-top:100px;
    margin-bottom:35px;
}
.headvalue {
   
    padding-bottom:10px;
}
.cont{
    text-align:center;
}
.copn{
    margin:20px;
}

@media (max-width: 768px) {
    .headvalue {
        padding-bottom: 1px;
    }
    .gifttext {
        padding: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #1F274A;
    }
}
.kwbSeW div.views span {
  font-size: 15px;
}
@media (min-width: 300px) and (max-width: 580px) and (max-width: 700px) {
  .kwbSeW div.views span {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .kwbSeW div.views span {
    font-size: 12px;
  }
}
.roe {
  /* margin-top: 9%; */
  margin-top: 8%;
  padding-left: 25px;
  margin-bottom: 5px;
  /* @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 15%;
  } */
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 20%;
  }
  /* @media (min-width: 581px) and (max-width: 768px) {
    margin-top: 6%;
  } */
}

.kwbSeW {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-color: transparent;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  height: 100%;
  border-radius: 15px;
  margin-top: 10px;
}

.description {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (orientation: portrait) {
  .kwbSeW {
    -webkit-text-decoration: none;
    text-decoration: none;
    border-color: transparent;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    height: 120px;
    border-radius: 15px;
  }

  .ant-card-bordered .ant-card-cover {
    float: left;
  }

  .kwbSeW .ant-card-cover img {
    border-radius: 15px 15px 15px 15px;
    width: 70px;
    height: 80px;
    margin: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .card-tablets {
    display: flex;
    flex-direction: column;
  }
}

.empmsg {
  border-radius: 15px;
  color: #afafaf;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.appointmentsHeading{
  font-size: 24px;
  line-height: 32px;
  /*height: 65px;*/
  font-weight: 600;
  text-align: left;
  /* margin-left: 8px; */
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appointmentsHeadingH1 {
  font-size: 28px;
  line-height: 32px;
  /*height: 65px;*/
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appointmentsPara{
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 16px;
  margin-bottom: 8px;
}
.appointmentsPara1 {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 32px;
  margin-bottom: 12px;
}
ul.appointmentsList1{
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  text-align: left;
  margin-left: -12px;
}
ul.appointmentsList {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
}
ul.appointmentsList1 li{
  list-style-type: none !important;
  display: inline-block;
  padding: 0px 15px;
  margin: 0px 0px 8px 0px;
}
ul.appointmentsList li {
  list-style-type: none !important;
  display: inline-block;
  padding: 0px 15px;
  font-size: 14px;
}
a.appointmentsBtn {
  width: 100%;
  margin: 0px auto;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  /* padding: 6px 10px; */
  text-decoration: none;
  /* background: linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important; */
  color: #a54ea5;
  /* border-radius: 30px; */
  /* color: #FFFFFF; */
  border: none;
  text-align: center;
}
.appmntImg{
  background: lightgray;
  border-radius: 20px;
}
.appmntImg2{
  background: lightgray;
  border-radius: 20px;
  min-height: 170px !important;
  max-height: 170px !important;
  overflow: hidden;
}
.appmntImg img {
  border-radius: 15px;
  width: 100%;
}
.appmntImg2 img {
  border-radius: 15px;
  width: 100%;
  height: 100% !important;
}
@media(min-width: 577px) and (max-width: 768px){
  .appointmentsHeadingH1{
    margin-top: 16px;
  }
  .appointmentsPara1{
    font-size: 12px;
    margin-bottom: 8px;
  }
  ul.appointmentsList {
    margin-bottom: 8px;
  }
  ul.appointmentsList li{
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .appointmentsHeadingH1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .appointmentsHeading{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  a.appointmentsBtn {
    font-size: 14px;
  }
  ul.appointmentsList {
    margin-bottom: 10px;
  }
  ul.appointmentsList1{
    margin-bottom: 8px;
  }
  ul.appointmentsList1 li{
    margin: 3px 0px; 
  }
  ul.appointmentsList li {
    margin: 3px 0px;
  }
  .appointmentBox {
    padding: 5px 5px 60px 5px !important;
  }
  /*
    .appointmentsHeadingH1{
        font-size: 24px;
        line-height: 26px;
    }
    ul.appointmentsList li{
        margin:3px 0px
    }
    */
}
.svimg {
  height: 180px;
  width: 100%;
  @media(max-width: 576px){
    height: 135px;
  }
}
.conts {
  position: absolute;
  bottom: 0;
  line-height: 5;
}

@media only screen and (orientation: portrait) {
  .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    font-size: 12px;
  }
  .reportTabWrap .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
    padding: 9px 13px;
  }
}

.itemscard {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 8px;
  height: 100%;
}

.cardSection3{
  position: absolute;
  z-index: 99;
  left: 0;
  bottom: 15px;
}
body{
    font-family: "Poppins" !important;
    color: #1F274A;
}
/* .regitLogo{
    margin-top:15px;
}
h1.regitWelcome{
    font-size: 40px;
    font-weight: 600;
}
p.regitPara1{
    font-size: 20px;
    color: rgba(31, 39, 74, 0.7);
}
.Regitlabel{
    font-size: 15px;
    font-weight: 400;
}
.regitForgotLbl{
    font-size: 16px;
    font-weight: 600;
    color: #CF509B;
}
.regitBtn{
    width:100%;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding: 7px 0px;
    background: linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important;
    border-radius: 10px;
    color: #FFFFFF;
    border: none;
}
ul.regitButtonList{
    margin: 0px;
    padding: 0;
}
ul.regitButtonList li{
    display: block;
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid rgba(31, 39, 74, 0.2);
    border-radius: 10px;
}
ul.regitButtonList li:last-child{
    margin-bottom: 0px;
}
ul.regitButtonList li a{
    display: block;
    font-size: 16px;
    line-height: 40px;
    font-weight:400;
    color: #1F274A;
}
ul.regitButtonList li a svg{
    font-size: 30px;
    line-height: 16px;
    height:40px;
    font-weight:400;
    margin-right:5px;
    margin-top: -5px;
}

.socialIconMargin{
   margin-right: 15px;
}

.regitLableBottom{
    font-size: 16px;
    font-weight: 600;
    color: #1F274A !important;
}
.regitLableBottom a{
    color: #CF509B !important;
    text-decoration: none;
}
.regitRightImage2{
    position: fixed;
    right: 0;
    margin-top: 10%;
    bottom: -90px;
}
@media (max-width: 768px) {
    .regitRightImage2{
        position: relative;
        right: auto;
        margin-top: auto;
        bottom: auto;
    }
    .regitLogo{
        display: none;
    }
	.regitMobileImg{
        background: #F8F8F8;
        border-radius: 0px 0px 30px 30px;
    }
    h1.regitWelcome{
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .regitPara1{
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }
    .Regitlabel{
        display: none;
    }
    .regitForgotLbl{
        font-size: 14px;
        line-height: 14px;
    }
    .regitBtn{
        font-size: 16px;
        line-height: 16px;
        padding:15px 0px;
    }
    ul.regitButtonList li{
        box-shadow: 1px 6px 22px #EDE8ED;
    }
    ul.regitButtonList li a{
        font-size: 16px;
    }
}
.img-fix {
    width: 100%;
    height:100%;
}

.ModalHeader .ant-modal-header{
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
@media (max-width: 576px) {
    .img-fix{
        width:100%;
        display:none;
    }
    .contents-login {
        margin: 20px;
    }
   
}
@media (max-width:576px) {
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 60px;
        color: #1F274A;
    }
}
.logpg {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    /* color: rgba(31, 39, 74, 0.7);
}
.lbsc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    /* color: #1F274A; */
/* } */
/* .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #1F274A;
}
.logos {
    margin-top:50px;
    margin-bottom:20px
}
.welcome{
    @media(max-width: 320px){
        font-size: 32px;
    }
}  */

.main-heading{
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
}

.text{
    font-size: 16px;
}

.sub-heading{
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 600;
}

.small-heading{
    font-size: 18px;
    font-weight: 500;
}

.list{
    margin-left: 16px;
    margin-bottom: 16px;
}

.list li{
    margin-bottom: 8px;
}

.extra-margin{
    margin-bottom: 32px;
}
