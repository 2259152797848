.kwbSeW div.views span {
  font-size: 15px;
}
@media (min-width: 300px) and (max-width: 580px) and (max-width: 700px) {
  .kwbSeW div.views span {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .kwbSeW div.views span {
    font-size: 12px;
  }
}
.roe {
  /* margin-top: 9%; */
  margin-top: 8%;
  padding-left: 25px;
  margin-bottom: 5px;
  /* @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 15%;
  } */
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 20%;
  }
  /* @media (min-width: 581px) and (max-width: 768px) {
    margin-top: 6%;
  } */
}

.kwbSeW {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-color: transparent;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  height: 100%;
  border-radius: 15px;
  margin-top: 10px;
}

.description {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (orientation: portrait) {
  .kwbSeW {
    -webkit-text-decoration: none;
    text-decoration: none;
    border-color: transparent;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    height: 120px;
    border-radius: 15px;
  }

  .ant-card-bordered .ant-card-cover {
    float: left;
  }

  .kwbSeW .ant-card-cover img {
    border-radius: 15px 15px 15px 15px;
    width: 70px;
    height: 80px;
    margin: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .card-tablets {
    display: flex;
    flex-direction: column;
  }
}
