.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-btn {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid transparent;
  transition: border-color 0.3s, color 0.3s;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 4px 0px 12px 0px;
  border: 1px solid lightgray;
}

.category-btn:last-child{
    margin-bottom: 0;
}

.category-btn:hover {
  border-color: #cf509b;
  color: #cf509b;
}

.ant-modal-header{
border-radius: 20px 20px 0px;
}

.selected{
  border: 1px solid transparent;
  transition: border-color 0.3s, color 0.3s;
  border-color: #a54ea5;
  color: #a54ea5;
}