body{
    font-family: "Poppins" !important;
    color: #1F274A;
}
/* .regitLogo{
    margin-top:15px;
}
h1.regitWelcome{
    font-size: 40px;
    font-weight: 600;
}
p.regitPara1{
    font-size: 20px;
    color: rgba(31, 39, 74, 0.7);
}
.Regitlabel{
    font-size: 15px;
    font-weight: 400;
}
.regitForgotLbl{
    font-size: 16px;
    font-weight: 600;
    color: #CF509B;
}
.regitBtn{
    width:100%;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding: 7px 0px;
    background: linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important;
    border-radius: 10px;
    color: #FFFFFF;
    border: none;
}
ul.regitButtonList{
    margin: 0px;
    padding: 0;
}
ul.regitButtonList li{
    display: block;
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid rgba(31, 39, 74, 0.2);
    border-radius: 10px;
}
ul.regitButtonList li:last-child{
    margin-bottom: 0px;
}
ul.regitButtonList li a{
    display: block;
    font-size: 16px;
    line-height: 40px;
    font-weight:400;
    color: #1F274A;
}
ul.regitButtonList li a svg{
    font-size: 30px;
    line-height: 16px;
    height:40px;
    font-weight:400;
    margin-right:5px;
    margin-top: -5px;
}

.socialIconMargin{
   margin-right: 15px;
}

.regitLableBottom{
    font-size: 16px;
    font-weight: 600;
    color: #1F274A !important;
}
.regitLableBottom a{
    color: #CF509B !important;
    text-decoration: none;
}
.regitRightImage2{
    position: fixed;
    right: 0;
    margin-top: 10%;
    bottom: -90px;
}
@media (max-width: 768px) {
    .regitRightImage2{
        position: relative;
        right: auto;
        margin-top: auto;
        bottom: auto;
    }
    .regitLogo{
        display: none;
    }
	.regitMobileImg{
        background: #F8F8F8;
        border-radius: 0px 0px 30px 30px;
    }
    h1.regitWelcome{
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .regitPara1{
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }
    .Regitlabel{
        display: none;
    }
    .regitForgotLbl{
        font-size: 14px;
        line-height: 14px;
    }
    .regitBtn{
        font-size: 16px;
        line-height: 16px;
        padding:15px 0px;
    }
    ul.regitButtonList li{
        box-shadow: 1px 6px 22px #EDE8ED;
    }
    ul.regitButtonList li a{
        font-size: 16px;
    }
}
.img-fix {
    width: 100%;
    height:100%;
}

.ModalHeader .ant-modal-header{
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
@media (max-width: 576px) {
    .img-fix{
        width:100%;
        display:none;
    }
    .contents-login {
        margin: 20px;
    }
   
}
@media (max-width:576px) {
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 60px;
        color: #1F274A;
    }
}
.logpg {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    /* color: rgba(31, 39, 74, 0.7);
}
.lbsc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    /* color: #1F274A; */
/* } */
/* .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #1F274A;
}
.logos {
    margin-top:50px;
    margin-bottom:20px
}
.welcome{
    @media(max-width: 320px){
        font-size: 32px;
    }
}  */

.main-heading{
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
}

.text{
    font-size: 16px;
}

.sub-heading{
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 600;
}

.small-heading{
    font-size: 18px;
    font-weight: 500;
}

.list{
    margin-left: 16px;
    margin-bottom: 16px;
}

.list li{
    margin-bottom: 8px;
}

.extra-margin{
    margin-bottom: 32px;
}