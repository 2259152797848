@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.registerImg{
    position: absolute;
    right: 0;
    bottom: 0;
    top:0%;
    z-index: -1;
}
.registerImg img{
    height: 100%;
    margin-right:0px;
}
body{
    font-family: 'Poppins', sans-serif;
    color: #1F274A;
}
.regitLogo{
    margin-top:15px;
}
h1.regitWelcome{
    font-size: 34px;
    font-weight: 600;
}
p.regitPara1{
    font-size: 20px;
    color: rgba(31, 39, 74, 0.7);
}
.Regitlabel{
    font-size: 15px;
    font-weight: 400;
}
.regitForgotLbl{
    font-size: 16px;
    font-weight: 600;
    color: #a54ea5;
}
.regitBtn{
    width:100%;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding: 7px 0px;
    background:  linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%) !important;
    border-radius: 10px;
    color: #FFFFFF;
    border: none;
}
ul.regitButtonList{
    margin: 0px;
    padding: 0;
}
ul.regitButtonList li{
    display: block;
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid rgba(31, 39, 74, 0.2);
    border-radius: 10px;
}
ul.regitButtonList li:last-child{
    margin-bottom: 0px;
}
ul.regitButtonList li a{
    display: block;
    font-size: 16px;
    line-height: 40px;
    font-weight:400;
    color: #1F274A;
}
ul.regitButtonList li a svg{
    font-size: 30px;
    line-height: 16px;
    height:40px;
    font-weight:400;
    margin-right:5px;
    margin-top: -5px;
}
.regitFacebook{
    border-radius: 15px;
    color: #4267B2;
}
.regitGoogle{
    color: rgb(219, 50, 54);
}
.regitFacebook svg{
    border-radius: 15px;
}
.regitLableBottom{
    font-size: 16px;
    font-weight: 600;
    color: #1F274A !important;
}
.regitLableBottom a{
    color: #a54ea5 !important;
    text-decoration: none;
}
/************ Modal ***********/
.modalTitleH1{
    margin: 0px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: #1F274A;
}
.modalSubTitleH6{
    margin: 0px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #1F274A;
}
.modalOTP input{
    background: #FAFAFF;
    border: 1px solid #A3A9BF;
    border-radius: 5px;
}
.modalOTP input:focus{
    background: #FAFAFF;
    border:none;
    outline: 1px solid #A3A9BF;
    border-radius: 5px;
}
.modalResendBtn{
    color: #A64FA6;
    background: none;
}
.modalResendBtn:hover{
    color: #A64FA6;
    background: none;
}
.verifyButton{
    width: 60%;
    margin: 0px auto;
    background: linear-gradient(89.63deg, #DB7DDB 0.32%, #C473AC 0.33%, #A64FA6 99.68%);
    border-radius: 23px;
    border:none;
    text-align: center;
}
.verifyButton:hover{
    background: linear-gradient(89.63deg, #DB7DDB 0.32%, #C473AC 0.33%, #A64FA6 99.68%);
}
.react-confirm-alert-button-group button{
    background:linear-gradient(270deg, #FF7CC9 0%, #CF509B 100%) !important;
    font-weight: 400;
}
.react-confirm-alert-body h1{
    font-size: 1.8rem;
}

@media (max-width: 768px) {
    .registerImg{
        position: relative;
        right: auto;
        bottom: auto;
        top:auto;
    }
    .regitLogo{
        display: none;
    }
	.regitMobileImg{
        background: #F8F8F8;
        border-radius: 0px 0px 30px 30px;
    }
    h1.regitWelcome{
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .regitPara1{
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }
    .Regitlabel{
        display: none;
    }
    .regitForgotLbl{
        font-size: 14px;
        line-height: 14px;
    }
    .regitBtn{
        font-size: 16px;
        line-height: 16px;
        padding:15px 0px;
    }
    ul.regitButtonList li{
        box-shadow: 1px 6px 22px #EDE8ED;
    }
    ul.regitButtonList li a{
        font-size: 16px;
    }
    .img-fix-signup {
        width: 100%;
        display: none;
    }
}
.img-fix-signup {
    width: 100%;
    height:100%;
}
.img-fix-logo {
    width: 200px;
    height: 100%;
}
.contents-login {
    margin-left: 107px;
}

@media (max-width: 576px) {
    .img-fix-signup {
        width: 100%;
        display: none;
    }
    .contents-login {
        margin: 20px;
    }
}

@media (max-width:992px) {
    .contents-login {
        margin: 20px;
    }
    .img-fix-signup {
        width: 100%;
        display: none;
    }
    /* .img-fixs {
        width: 100%;
        height: 100%;
        display: block;
    } */
    .img-fix-logo {
        width: 100%;
        height: 100%;
    }
}
/* .img-fixs{
    display:none;
} */

.logpg-signup {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: rgba(31, 39, 74, 0.7);
}

.lbsc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    color: #1F274A;
}

.title-signup {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: #1F274A;
    @media(max-width: 576px) {
        font-size: 24px;
    }
}

.logos-signup {
    margin-top: 16px;
    margin-bottom: 20px
}

/*********** Galaxy Fold ***********
************************************/
@media (max-width:281px) {        
    ul.regitButtonList li a svg{
        padding: 0px 15px;
    }
    .modalOTP input{
        width: 35px;
        height: 35px;
    }
    .react-confirm-alert-body{
        width: 95%;
        margin: 0px auto;
    }
    .react-confirm-alert-body h1{
        font-size: 1.1rem;
        font-weight: 600;
    }
}

.container-fluid{
    max-height: 100vh;
}